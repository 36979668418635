import React, {FC} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {getInspectionsCalenderData} from '../../setup/redux/common/actions'
import {RootState} from '../../setup'
import {Tooltip} from 'bootstrap'
import Select from 'react-select'
import * as actions from '../../setup/redux/common/slices'
import {Loader} from '../services/Loader'

export const Calender: FC = () => {
  const dispatch = useDispatch()
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [selectedUser, setSelectedUser] = React.useState({value: '', label: ''})

  function getCalendarData(event: any) {
    setStartDate(moment(event.startStr).format('YYYY-MM-DD'))
    setEndDate(moment(event.endStr).format('YYYY-MM-DD'))
  }

  function handleEventClick(info: any) {

  }

  const {inspectionsData, user, actionsLoading}: any = useSelector<RootState>((state) => ({
    inspectionsData: state.common.inspectionsCalenderData,
    user: state.auth.user,
    actionsLoading: state.common.actionsLoading,
  }), shallowEqual)
  React.useEffect(() => {
    if (startDate && selectedUser.value !== '') {
      let userId
      if (selectedUser.value !== '') {
        userId = selectedUser?.value
      } else {
        userId = user?.id
      }
      dispatch(getInspectionsCalenderData({
        startDate, endDate, userId,
      }))
    }
  }, [startDate, selectedUser])

  React.useEffect(() => {
    if (user && (user?.group?.id === 1 || user?.group?.id === 2)) {

    } else {
      setSelectedUser({value: user.id, label: user.name})
    }
  }, [user])

  React.useEffect(() => {
    dispatch(actions.CommonSlices.actions.resetCalenderData([]))
  }, [])

  function renderEventContent(info: any) {
    if (info.el && info.event?.extendedProps?.description) {
      new Tooltip(info.el, {
        title: info.event?.extendedProps?.description,
        html: true,
        placement: 'top',
        trigger: 'hover',
        container: 'body',
      })
    }
  }

  return (
    <>
      {actionsLoading && <Loader />}
      <div className="card">
        <div className="card-body">
          {user && (user?.group?.id === 1 || user?.group?.id === 2) && <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select company-users'
                  options={[]}
                  value={selectedUser}
                  name='users'
                  placeholder="Select users"
                  onChange={(option: any) => {
                    setSelectedUser(option)
                  }}
                />
              </div>
            </div>
          </div>}
          <FullCalendar
            eventDidMount={renderEventContent}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            eventTimeFormat={{
              timeStyle: 'short',
            }}
            initialView='dayGridMonth'
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            events={inspectionsData}
            eventClick={handleEventClick}
            datesSet={(arg: any) => getCalendarData(arg)}
            allDaySlot={false}
            defaultAllDay={false}
          />
        </div>
      </div>
    </>
  )
}
