import {createSlice} from '@reduxjs/toolkit'

const initialQuotationState = {
    totalCount: 0,
    bookings: null,
    booking: undefined,
    hotels: null,
    sightSeeings: null,
    transfers: null,
    packages: null,
    selectedAgentID: null,
    agentWalletInfo: null,
    guestNationality: 97,
    searchHotel: [],
    searchSightSeeing: [],
    searchTransfer: [],
    searchPackage: [],
    activeServiceIndex: '1',
    itinerary: {
        isPackage: true,
        bookingDetails: {
            pnr_no: '',
            agent_ref_no: '',
            lead_guest: '',
            start_date: '',
            end_date: '',
            adults: 0,
            child: 0,
            infant: 0,
            total_pax: 0,
            nationality: '',
        },
        uniqueAdultTravelers: [],
        uniqueChildTravelers: [],
        flightDetails: [],
        hotels: [],
        services: []
    },
    creditNote: null,
    invoiceNote: null
}

export const QuotationSlices = createSlice({
    name: 'bookings',
    initialState: initialQuotationState,
    reducers: {
        booking: (state, action) => {
            state.booking = action.payload.booking
        },
        hotels: (state, action) => {
            state.hotels = action.payload.hotels
        },
        sightSeeings: (state, action) => {
            state.sightSeeings = action.payload.sight_seeings
        },
        transfers: (state, action) => {
            state.transfers = action.payload.transfers
        },
        packages: (state, action) => {
            state.packages = action.payload.packages
        },
        bookings: (state, action) => {
            state.bookings = action.payload.bookings
            state.totalCount = action.payload.total_count
        },
        selectedAgent: (state, action) => {
            state.selectedAgentID = action.payload
        },
        agentWalletInfo: (state, action) => {
            state.agentWalletInfo = action.payload.agentWallet
        },
        selectedGuestNationality: (state, action) => {
            state.guestNationality = action.payload
        },
        searchHotelQuotation: (state, action) => {
            state.searchHotel = action.payload
        },
        searchSightSeeingQuotation: (state, action) => {
            state.searchSightSeeing = action.payload
        },
        searchTransferQuotation: (state, action) => {
            state.searchTransfer = action.payload
        },
        searchPackageQuotation: (state, action) => {
            state.searchPackage = action.payload
        },
        setActiveServiceIndex: (state, action) => {
            state.activeServiceIndex = action.payload
        },
        itineraryDetails: (state, action) => {
            state.itinerary.isPackage = action.payload.isPackage
            state.itinerary.bookingDetails = action.payload.bookingDetails
            state.itinerary.uniqueAdultTravelers = action.payload.uniqueAdultTravelers
            state.itinerary.uniqueChildTravelers = action.payload.uniqueChildTravelers
            state.itinerary.flightDetails = action.payload.flightDetails
            state.itinerary.hotels = action.payload.hotels
            state.itinerary.services = action.payload.services
        },
        creditNote: (state, actions) => {
            state.creditNote = actions.payload.creditNote
        },
        invoiceNote: (state, actions) => {
            state.invoiceNote = actions.payload.invoiceNote
        },
        resetQuotationInfo: (state, action) => {
            state.totalCount = 0;
            state.booking = undefined;
            state.selectedAgentID = null;
            state.agentWalletInfo = null;
            state.guestNationality = 97;
            state.searchHotel = [];
            state.searchSightSeeing = [];
            state.searchTransfer = [];
            state.searchPackage = [];
            state.activeServiceIndex = '1';
        },
    },
})
export const {searchHotelQuotation, searchSightSeeingQuotation, searchTransferQuotation, searchPackageQuotation} = QuotationSlices.actions
