import {createSlice} from '@reduxjs/toolkit'

const initialHotelAmenityState = {
  totalCount: 0,
  hotel_amenities: null,
  hotel_amenity: undefined,
  hotel_amenities_dropdown: [],
}

export const HotelAmenitySlices = createSlice({
  name: 'hotel_amenities',
  initialState: initialHotelAmenityState,
  reducers: {
    hotel_amenity: (state, action) => {
      state.hotel_amenity = action.payload.hotel_amenity
    },

    hotel_amenities: (state, action) => {
      state.hotel_amenities = action.payload.hotel_amenities
      state.totalCount = action.payload.total_count
    },

    hotel_amenities_dropdown: (state, action) => {
      state.hotel_amenities_dropdown = action.payload.hotel_amenities
    },
  },
})