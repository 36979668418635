import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {CheckAccess} from '../../services/Functional'
import {ManageCheckOut} from './pages/Manage'
import CheckoutDetails from './checkout_details/CheckoutDetails'
import {ManageListStatus} from "../../services/table/ManageListStatus";

const checkoutBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  // {
  //   title: 'Checkout',
  //   path: '/checkout',
  //   isSeparator: false,
  //   isActive: false,
  // },
]

const CheckOut = () => {
  return (
    <ManageListStatus>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='/'
            element={
              CheckAccess('quotations.create') ? (
                <>
                  <PageTitle module='CHECKOUT' breadcrumbs={checkoutBreadcrumbs}>
                    Checkout/View Cart
                  </PageTitle>
                  <ManageCheckOut />
                </>
              ) : (
                <Navigate to='/error' />
              )
            }
          />
          <Route
            path='/details'
            element={
              CheckAccess('quotations.create') ? (
                <>
                  <PageTitle module='CHECKOUT' breadcrumbs={checkoutBreadcrumbs}>
                    Checkout/Payment
                  </PageTitle>
                  <CheckoutDetails />
                </>
              ) : (
                <Navigate to='/error' />
              )
            }
          />
        </Route>
      </Routes>
    </ManageListStatus>
  )
}

export default CheckOut
