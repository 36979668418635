import moment from 'moment'

export function managePackageInitialData(packages: any) {
  const packagesInitialData: {
    package_data: any,
    package_id: number,
    pickup_date: string,
    system_price: number,
    sell_price: number,
    per_adult_price: number,
    per_child_price: number,
    purchase_price_usd: number,
    purchase_price_original: number,
    conversion_rate: number,
    availability: boolean,
    cancel_upto: string,
    special_request: string,
    adults: {prefix: string; first_name: string; last_name: string; pan_number: string}[],
    children: {prefix: string; first_name: string; last_name: string; age: any}[]
    hotels: {city_id: number, city_name: string, hotel_id: number, hotel_name: string}[],
    itineraries?: {
      id: number,
      day: string,
      service: string,
      service_type: string,
      date: string,
      time: string,
      type: string,
      city_from: number,
      city_to: number,
      flight_no: string,
      estimated_time: string,
      pickup_time: string,
      adult_price: number,
      child_price: number,
      sell_price: number,
      purchase_price_usd: number,
      purchase_price_original: number,
    }[]
  }[] = []
  if (packages && packages.length) {
    packages.map((packageData: any) => {
      const adultsInitialData = []
      const childInitialData = []
      for (let i = 0; i < packageData.searchData.adult; i++) {
        adultsInitialData.push({
          prefix: 'mr',
          first_name: '',
          last_name: '',
          pan_number: '',
        })
      }
      for (let i = 0; i < packageData.searchData.child_count; i++) {
        childInitialData.push({
          prefix: 'mr',
          first_name: '',
          last_name: '',
          age: packageData.searchData.child[i].age,
        })
      }
      const itineraries: {id: number, day: any; service: any; service_type: any; date: string; time: any; city_from: number; city_to: number; type: any; adult_price: number; child_price: number; sell_price: number; purchase_price_usd: number; purchase_price_original: number; flight_no: string; estimated_time: string; pickup_time: string;}[] = []
      const pickupDate = moment(packageData.searchData.pickup_date)
      for (const packageItinerary of packageData.itineraries) {
        itineraries.push({
          id: packageItinerary.id,
          day: packageItinerary.day,
          service: packageItinerary.service,
          service_type: packageItinerary.service_type,
          date: pickupDate.clone().add(parseInt(packageItinerary.day) - 1, 'day').format('YYYY-MM-DD'),
          time: packageItinerary.time,
          city_from: packageItinerary?.city_from?.id,
          city_to: packageItinerary?.city_to?.id,
          type: packageItinerary.type,
          adult_price: packageItinerary.adult_price,
          child_price: packageItinerary.child_price,
          sell_price: packageItinerary.sell_price,
          purchase_price_usd: packageItinerary.purchase_price_usd,
          purchase_price_original: packageItinerary.purchase_price_original,
          flight_no: '',
          estimated_time: '',
          pickup_time: '',
        })
      }
      packagesInitialData.push({
        package_data: packageData,
        package_id: packageData.package_response.id,
        pickup_date: packageData.searchData.pickup_date,
        system_price: packageData.price,
        sell_price: packageData.price,
        per_adult_price: packageData.adult_price,
        per_child_price: packageData.child_price,
        purchase_price_usd: packageData.purchase_price_usd,
        purchase_price_original: packageData.purchase_price_original,
        conversion_rate: packageData.conversion_rate,
        availability: packageData.availability,
        cancel_upto: packageData.cancel_upto,
        special_request: '',
        adults: adultsInitialData,
        children: childInitialData,
        hotels: packageData.hotels,
        itineraries: itineraries,
      })
    })
  }
  return packagesInitialData
}