export function manageTransferInitialData(transfers: any) {
    const transfersInitialData: {
        searchData: any,
        transfer_id: number,
        transfer_date: string,
        transfer_type: string,
        transfer_sub_type: string,
        pick_up_from_airport: number,
        pick_up_from_hotel: number,
        pick_up_from_other: string,
        pick_up_from_pier: number,
        drop_off_to_airport: number,
        drop_off_to_hotel: number,
        drop_off_to_other: string,
        drop_off_to_pier: number,
        system_price: number,
        sell_price: number,
        per_adult_price: number,
        per_child_price: number,
        purchase_price_usd: number,
        purchase_price_original: number,
        conversion_rate: number,
        combination: any,
        adults: { prefix: string; first_name: string; last_name: string; pan_number: string }[],
        children: { prefix: string; first_name: string; last_name: string; age: any }[],
        availability: boolean,
        cancel_upto: string,
        baggage_allow: number,
        flight_no: string,
        estimated_time: string,
        pickup_time: string,
        transfer_time: string,
        special_request: string
    }[] = []
    if (transfers && transfers.length) {
        transfers.map((transferData: any) => {
            const adultsInitialData = []
            const childInitialData = []
            for (let i = 0; i < transferData.searchData.adult; i++) {
                adultsInitialData.push({
                    prefix: 'mr',
                    first_name: '',
                    last_name: '',
                    pan_number: '',
                })
            }
            for (let i = 0; i < transferData.searchData.child_count; i++) {
                childInitialData.push({
                    prefix: 'mr',
                    first_name: '',
                    last_name: '',
                    age: transferData.searchData.child[i].age,
                })
            }
            transfersInitialData.push({
                searchData: transferData.searchData,
                transfer_id: transferData.transfer_response.id,
                transfer_date: transferData.searchData.transfer_date,
                transfer_type: transferData.transfer_type,
                transfer_sub_type: transferData.sub_type,
                pick_up_from_airport: transferData.searchData.pick_up_from_airport,
                pick_up_from_hotel: transferData.searchData.pick_up_from_hotel,
                pick_up_from_other: transferData.searchData.pick_up_from_other,
                pick_up_from_pier: transferData.searchData.pick_up_from_pier,
                drop_off_to_airport: transferData.searchData.drop_off_to_airport,
                drop_off_to_hotel: transferData.searchData.drop_off_to_hotel,
                drop_off_to_other: transferData.searchData.drop_off_to_other,
                drop_off_to_pier: transferData.searchData.drop_off_to_pier,
                system_price: transferData.price,
                sell_price: transferData.price,
                per_adult_price: transferData.adult_price,
                per_child_price: transferData.child_price,
                purchase_price_usd: transferData.price_data.purchase_price_usd,
                purchase_price_original: transferData.price_data.purchase_price_original,
                conversion_rate: transferData.price_data.conversion_rate,
                combination: transferData.price_data?.combination,
                adults: adultsInitialData,
                children: childInitialData,
                availability: transferData?.transfer.availability,
                cancel_upto: transferData?.transfer.cancel_upto,
                flight_no: '',
                baggage_allow: transferData.baggage_allow,
                estimated_time: '',
                pickup_time: transferData.pickup_time ?? '',
                transfer_time: transferData.transfer_time ?? '',
                special_request: ''
            })
        })
    }
    return transfersInitialData
}