import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLayout} from '../../../core'
import {usePageData} from '../../../core'
import {useState} from 'react'
import {BottomSheet} from 'react-spring-bottom-sheet'
import {useIntl} from 'react-intl'
import "react-spring-bottom-sheet/dist/style.css"

const PageTitle = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs, pageModuleName} = usePageData()
  const {config, classes} = useLayout()
  const [open, setOpen] = useState(false)
  const intl = useIntl()
  return (
    <div
      id='kt_page_title'
      // data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_toolbar_container'}"
      className={clsx('page-title d-flex w-100 justify-content-between', classes.pageTitle.join(' '))}
    >
      {/* begin::Title */}
      {pageTitle && (
        <h1 className='d-flex align-items-center text-gray-800 fw-bolder my-1 fs-3'>
          {pageTitle}
          {pageModuleName && <i className="ms-4 fa fa-info-circle cursor-pointer" onClick={() => setOpen(true)} />}
          {pageDescription && (
            <>
              <span className='h-20px border-gray-200 border-start ms-3 mx-2' />
              <small className='text-muted fs-7 fw-bold my-1 ms-1'>{pageDescription}</small>
            </>
          )}
        </h1>
      )}
      {/* end::Title */}

      {pageBreadcrumbs &&
      pageBreadcrumbs.length > 0 && (
        <>
          {/*{config.pageTitle.direction === 'row' && (
              <span className='h-20px border-gray-200 border-start mx-4'/>
            )}*/}
          <ul className='breadcrumb fw-bold fs-7 my-1 text-uppercase'>
            {Array.from(pageBreadcrumbs).map((item, index) => (
              <li
                className={clsx('breadcrumb-item', {
                  'text-dark': !item.isSeparator && item.isActive,
                  'text-muted': !item.isSeparator && !item.isActive,
                })}
                key={`${item.path}${index}`}
              >
                {!item.isSeparator ? (
                  <Link className='text-gray-600 text-hover-primary' to={item.path}>
                    {item.title}
                  </Link>
                ) : (
                  <span className='bullet bg-gray-200 w-5px h-2px' />
                )}
              </li>
            ))}
            <li className='breadcrumb-item text-gray-800'>{pageTitle}</li>
          </ul>
        </>
      )}
      {pageModuleName && <BottomSheet open={open} style={{zIndex: 99999}} onDismiss={() => setOpen(false)} className="bottom-sheet">
        <div className="my-10 text-center px-6">
          <h1 className="mb-8">{intl.formatMessage({id: 'PURPOSE.TITLE'})}</h1>
          <p>{intl.formatMessage({id: `PURPOSE.${pageModuleName}.DESCRIPTION`})}</p>
        </div>
      </BottomSheet>}
    </div>
  )
}

export {PageTitle}
