export function manageHotelInitialData(hotels: any) {
    const hotelsInitialData: {
        hotel_id: number,
        check_in: string,
        check_out: string,
        total_night: number,
        total_rooms: number,
        rooms: any[],
        conversion_rate: number,
        non_refundable: boolean,
        special_request: string,
    }[] = []
    if (hotels && hotels.length) {
        hotels.map((hotel: any, hotelIndex: number) => {
            const roomsInitialData: {
                availability: boolean,
                cancel_upto: string,
                room_id: number,
                system_price: number,
                sell_price: number,
                booking_code: string,
                purchase_price_usd: number,
                conversion_rate: number,
                adults: {prefix: string; first_name: string; last_name: string; pan_number: string}[],
                children: {prefix: string; first_name: string; last_name: string; age: any}[]
            }[] = []
            hotel.room_info.map((room: any, roomIndex: number) => {
                const adultsInitialData = []
                const childInitialData = []
                for (let i = 0; i < room.searchData.adult; i++) {
                    adultsInitialData.push({
                        prefix: 'mr',
                        first_name: '',
                        last_name: '',
                        pan_number: '',
                    })
                }
                for (let i = 0; i < room.searchData.child_count; i++) {
                    childInitialData.push({
                        prefix: 'mr',
                        first_name: '',
                        last_name: '',
                        age: room.searchData.child[i].age,
                        with_bed: room.searchData.child[i].child_bed_type,
                    })
                }
                roomsInitialData.push({
                    availability: room.roomData.availability,
                    cancel_upto: room.roomData.cancel_upto,
                    sell_price: room.roomData.price,
                    system_price: room.roomData.price,
                    booking_code: room.roomData.booking_code,
                    purchase_price_usd: room.roomData.purchase_price_usd,
                    conversion_rate: hotel.hotel.conversion_rate,
                    room_id: room.roomData.id,
                    adults: adultsInitialData,
                    children: childInitialData,
                })
            })
            hotelsInitialData.push({
                hotel_id: hotel.hotel.id,
                check_in: hotel.booking_info.check_in,
                check_out: hotel.booking_info.check_out,
                total_night: hotel.booking_info.total_night,
                total_rooms: hotel.booking_info.total_rooms,
                rooms: roomsInitialData,
                conversion_rate: hotel.hotel.conversion_rate,
                non_refundable: hotel.booking_info.non_refundable,
                special_request: ''
            })
        })
    }
    return hotelsInitialData
}