import {createSlice} from '@reduxjs/toolkit'

const initialSupplierTypeState = {
  totalCount: 0,
  supplier_types: null,
  supplier_type: undefined,
  suppliersDropdown: []
}

export const SupplierTypeSlices = createSlice({
  name: 'supplier_types',
  initialState: initialSupplierTypeState,
  reducers: {
    supplier_type: (state, action) => {
      state.supplier_type = action.payload.supplier_type
    },

    supplier_types: (state, action) => {
      state.supplier_types = action.payload.supplier_types
      state.totalCount = action.payload.total_count
    },
    supplierList: (state, action) => {
      state.suppliersDropdown = action.payload.suppliers;
    }
  },
})