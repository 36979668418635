import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
// @ts-ignore
import Toastify from 'toastify-js'
import 'toastify-js/src/toastify.css'
import React from 'react'
import {useIntl} from 'react-intl'
import {RootState} from '../../setup'
import {serialize} from 'object-to-formdata'
import moment from 'moment/moment'

export function CheckAccess(module: any) {
  const accesses = useSelector<RootState>(({auth}) => auth.accesses, shallowEqual)
  if (accesses) {
    // @ts-ignore
    return accesses.includes(module)
  }
  return false
}

export function CheckModulesAccess(modules: string[]) {
  const accesses = useSelector<RootState>(({auth}) => auth.accesses, shallowEqual)
  if (accesses) {
    // @ts-ignore
    return accesses.some((ai) => modules.includes(ai))
  }
  return false
}

export function IsRootUserOrAdmin() {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  return user?.is_root || user?.group?.id === 1
}

export const handleNotificationPrompt = (
  title: any,
  message: any,
  icon: any,
  history?: any,
  redirect_to: any = '',
) => {
  Swal.fire({
    title: title,
    html: message,
    icon: icon,
    allowOutsideClick: false,
  }).then(() => {
    Swal.close()
    if (redirect_to !== '') {
      history.push(redirect_to)
    }
  })
}

export const handleNotificationWithToastify = (
  data: any,
  history: any = null,
  redirect_to: any = '',
) => {
  Toastify({
    text: data.message,
    duration: 3000,
    newWindow: true,
    gravity: 'top',
    position: 'right',
    className: data?.success ? 'toastify-info' : 'toastify-error',
    stopOnFocus: true,
    onClick: function() {
      if (redirect_to !== '') {
        history.push(redirect_to)
      }
    },
  }).showToast()
}

export const SYSTEM_MODULES = {
  navigation: {
    administrative_links: {
      booking: [
        'bookings.index',
        // 'bookings.create',
        'bookings.show',
        'bookings.edit',
        'bookings.delete',
      ],
      quotation: [
        'quotations.index',
        'quotations.create',
        'quotations.show',
        'quotations.edit',
        'quotations.delete',
      ],
      group: ['groups.index', 'groups.create', 'groups.show', 'groups.edit', 'groups.delete'],
      configuration: ['configurations.index'],
      calendar: ['calendar.index'],
    },

    masters: {
      agent: ['agents.index', 'agents.create', 'agents.show', 'agents.edit', 'agents.delete'],
      hotel: [
        'hotels.index',
        'hotels.create',
        'hotels.show',
        'hotels.edit',
        'hotels.delete',
        'hotels.room',
        'hotels.markup',
        'hotels.stop_sale',
        'hotels.allotment',
        'hotels.price',
      ],
      sight_seeing: [
        'sight_seeings.index',
        'sight_seeings.create',
        'sight_seeings.show',
        'sight_seeings.edit',
        'sight_seeings.delete',
        'sight_seeings.stop_sale',
      ],
      user: ['users.index', 'users.create', 'users.show', 'users.edit', 'users.delete'],
      leave: [
        'leaves.index',
        'leaves.create',
        'leaves.show',
        'leaves.edit',
        'leaves.delete',
        'leaves.approved',
      ],
      attendance: [
        'attendances.index',
        // 'attendances.create',
        'attendances.show',
        'attendances.edit',
        'attendances.delete',
      ],
      banner: ['banners.index', 'banners.create', 'banners.show', 'banners.edit', 'banners.delete'],
      document_type: [
        'document_types.index',
        'document_types.create',
        'document_types.show',
        'document_types.edit',
        'document_types.delete',
      ],
      hotel_amenity: [
        'hotel_amenities.index',
        'hotel_amenities.create',
        'hotel_amenities.show',
        'hotel_amenities.edit',
        'hotel_amenities.delete',
      ],
      room_amenity: [
        'room_amenities.index',
        'room_amenities.create',
        'room_amenities.show',
        'room_amenities.edit',
        'room_amenities.delete',
      ],
      country: [
        'countries.index',
        'countries.create',
        'countries.show',
        'countries.edit',
        'countries.delete',
      ],
      city: ['cities.index', 'cities.create', 'cities.show', 'cities.edit', 'cities.delete'],
      pier: ['piers.index', 'piers.create', 'piers.show', 'piers.edit', 'piers.delete'],
      airport: [
        'airports.index',
        'airports.create',
        'airports.show',
        'airports.edit',
        'airports.delete',
      ],
      master_hotel: [
        'master_hotels.index',
        'master_hotels.create',
        'master_hotels.show',
        'master_hotels.edit',
        'master_hotels.delete',
      ],
      leave_type: [
        'leave_types.index',
        'leave_types.create',
        'leave_types.edit',
        'leave_types.delete',
      ],
      supplier: [
        'suppliers.index',
        'suppliers.create',
        'suppliers.show',
        'suppliers.edit',
        'suppliers.delete',
      ],
      supplier_type: [
        'supplier_types.index',
        'supplier_types.create',
        'supplier_types.show',
        'supplier_types.edit',
        'supplier_types.delete',
      ],
      transfer: [
        'transfers.index',
        'transfers.create',
        'transfers.show',
        'transfers.edit',
        'transfers.delete',
      ],
      package: [
        'packages.index',
        'packages.create',
        'packages.show',
        'packages.edit',
        'packages.delete',
      ],
    },
  },
}

export function BackButton() {
  const navigate = useNavigate()
  return (
    <button
      className='btn btn-secondary'
      type='button'
      onClick={() => {
        navigate(-1)
        /*Swal.fire({
          icon: 'warning',
          title: 'Are you sure?',
          text: 'The changes added would be lost, do you still want to exit?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true,
          allowOutsideClick: false,
        }).then(result => {
          if (result.isConfirmed) {
            navigate(-1)
          }
        })*/
      }}
    >
      Back
    </button>
  )
}

export function BackButtonForDetailPage({path = ''}) {
  const navigate = useNavigate()
  return (
    <button
      className='btn btn-outline-secondary'
      type='button'
      onClick={() => {
        path ? navigate(path) : navigate(-1)
      }}
    >
      Back
    </button>
  )
}

export function PurposeForm({type}: any) {
  const intl = useIntl()
  return (
    <div className='col-md-4'>
      <div className='card gutter-b'>
        <div className='card-body'>
          <p className='font-weight-bolder text-uppercase mb-10'>
            {intl.formatMessage({id: `PURPOSE.${type}.TITLE`})}
          </p>
          <p>{intl.formatMessage({id: `PURPOSE.${type}.DESCRIPTION`})}</p>
        </div>
      </div>
    </div>
  )
}

export function SubmitForm({handleSubmit, values}: any) {
  return (
    <div className='card gutter-b'>
      <div className='card-body pb-0'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-group'>
              <button
                type='submit'
                name='submit'
                className='btn btn-primary me-2'
                onSubmit={() => handleSubmit()}
              >
                {values.id ? 'Update' : 'Create'}
              </button>
              <BackButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const logoutPopup = (navigate: any) => {
  Swal.fire({
    icon: 'warning',
    title: 'Are you sure?',
    text: 'You want to logout?',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
    reverseButtons: true,
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      navigate('/logout')
    }
  })
}

export const handleManualChange = (handleChange: any, name: string, value: string) => {
  let event = {
    target: {
      name: name,
      value: value,
    },
  }
  handleChange(event)
}

export const manageLocation = (place: any, handleChange: any) => {
  if (place === undefined) {
    return false
  }
  handleManualChange(handleChange, `agent_address`, place.formatted_address)
  for (const component of place.address_components) {
    const componentType = component.types[0]
    switch (componentType) {
      case 'postal_code':
        handleManualChange(handleChange, `agent_pincode`, component.long_name)
        break

      case 'administrative_area_level_1':
        handleManualChange(handleChange, `agent_state`, component.long_name)
        break

      case 'administrative_area_level_2':
      case 'administrative_area_level_3':
        handleManualChange(handleChange, `agent_city`, component.long_name)
        break

      case 'country':
        handleManualChange(handleChange, `agent_country`, component.long_name)
        break
    }
  }
  // handleManualChange(handleChange, `latitude`, place.geometry.location.lat())
  // handleManualChange(handleChange, `longitude`, place.geometry.location.lng())
}

export const checkEmpty = (value: string) => {
  return value ? value : '-'
}
export const getRoomInclusion = (value: string) => {
  switch (value) {
    case 'Room':
      return 'Room Only'
    case 'BF':
      return 'Breakfast'
    case 'H/B':
      return 'Half Board'
    case 'F/B':
      return 'Full Board'
  }
}

export const serializeValueWithArray = (values: any) => {
  const options = {
    indices: true,
    nullsAsUndefineds: false,
    booleansAsIntegers: false,
    allowEmptyArrays: true,
  }
  return serialize(values, options)
}

export const AuthFooter = () => (
  <p className='hide_on_mobile' style={{position: 'absolute', bottom: '10px'}}>
    <span className='text-gray-800'>
      Copyright &copy; {new Date().getFullYear()}
      &nbsp; ATM DMC. All rights reserved.
    </span>
  </p>
)

export const getInitials = (name: any) => {
  name = !!name ? name.split(' ') : ['Amigo', 'Amigo']
  if (name.length > 2) {
    name = [name[0], name[name.length - 1]]
  }
  return name.length > 1
    ? name[0].substring(0, 1).toUpperCase() + name[1].substring(0, 1).toUpperCase()
    : name[0].substring(0, 1).toUpperCase()
}

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const updateMarketOrder = (allMarkets: any[]) => {
  let markets: any[] = []
  if (allMarkets && allMarkets?.length !== 0) {
    if (allMarkets.includes('indian')) {
      markets.push('indian')
    }
    if (allMarkets.includes('subcontinent')) {
      markets.push('subcontinent')
    }
    if (allMarkets.includes('middleeast')) {
      markets.push('middleeast')
    }
    if (allMarkets.includes('worldwide')) {
      markets.push('worldwide')
    }
  }
  return markets
}

export const DisplayCopyButton = ({markets, market, setFieldValue, values}: any) => {
  if (markets.length >= 2) {
    if (market === 'subcontinent' && markets.includes('indian')) {
      return (
        <button
          className='btn btn-primary ms-2 btn-sm'
          onClick={(e) => {
            e.preventDefault()
            setFieldValue(`prices[${market}]`, values.prices.indian)
          }}
        >
          Copy
        </button>
      )
    } else if (market === 'middleeast') {
      const commonValues = markets.find((item: any) => ['indian', 'subcontinent'].includes(item))
      return (
        <button
          className='btn btn-primary ms-2 btn-sm'
          onClick={(e) => {
            e.preventDefault()
            setFieldValue(`prices[${market}]`, values.prices[commonValues])
          }}
        >
          Copy
        </button>
      )
    } else if (market === 'worldwide') {
      const commonValues = markets.find((item: any) =>
        ['indian', 'subcontinent', 'middleeast'].includes(item),
      )
      return (
        <button
          className='btn btn-primary ms-2 btn-sm'
          onClick={(e) => {
            e.preventDefault()
            setFieldValue(`prices[${market}]`, values.prices[commonValues])
          }}
        >
          Copy
        </button>
      )
    }
    return null
  } else {
    return null
  }
}

export function AddToCartSwalFire() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  })

  Toast.fire({
    icon: 'success',
    title: 'Add to cart successfully.',
  })
  return null
}

export function DateFormat(props: {items: any}) {
  return <div className={'text-muted'}>{moment(props.items).format('DD/MM/YYYY')}</div>
}

export function TimeFormat(props: {time: string}) {
  return <div className={'text-muted'}>{moment(props?.time, '"HH:mm:ss"').format('HH:mm A')}</div>
}