import React, {useEffect, useMemo, useState} from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {ErrorMessage, Field, Formik} from 'formik'
import {HotelCheckOutDetails, PrefixOption} from './hotel_checkout_details/HotelCheckOutDetails'
import * as Yup from 'yup'
import moment from 'moment'
import Select from 'react-select'
import {Input} from '../../../../_metronic/partials/controls'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {createBooking} from '../../../../setup/redux/bookings/actions'
import {useNavigate} from 'react-router-dom'
import {agentWalletInfo, resetBookingInfo} from '../../../../setup/redux/bookings/slices'
import {manageLeadPassengerDetails} from '../helper/manageLeadPassengerDetails'
import {SightSeeingCheckOutDetails} from './sight_seeing_checkout_details/SightSeeingCheckOutDetails'
import {manageHotelInitialData} from '../helper/manageHotelInitialData'
import {manageSightSeeingInitialData} from '../helper/manageSightSeeingInitialData'
import {PackageCheckOutDetails} from './package_checkout_details/PackageCheckOutDetails'
import {managePackageInitialData} from '../helper/managePackageInitialData'
import {TransferCheckOutDetails} from './transfer_checkout_details/TransferCheckOutDetails'
import {manageTransferInitialData} from '../helper/manageTransferInitialData'
import {ConnectedFocusError} from "focus-formik-error";
import {Loader} from "../../../services/Loader";
import {CheckBookingInCancellationPeriod} from "../../bookings/helpers/CheckBookingInCancellationPeriod";

const hotelSchema = Yup.object().shape({
    rooms: Yup.array().of(
        Yup.object().shape({
            adults: Yup.array().of(
                Yup.object().shape({
                    prefix: Yup.string().label('Prefix'),
                    first_name: Yup.string().required().label('First name'),
                    last_name: Yup.string().required().label('Last name'),
                    pan_number: Yup.string(),
                }),
            ),
            children: Yup.array().of(
                Yup.object().shape({
                    prefix: Yup.string().required().label('Prefix'),
                    first_name: Yup.string().required().label('First name'),
                    last_name: Yup.string().required().label('Last name'),
                    age: Yup.number().required().label('Age'),
                }),
            ),
        }),
    ),
})
const sightSeeingsSchema = Yup.object().shape({
    adults: Yup.array().of(
        Yup.object().shape({
            prefix: Yup.string().label('Prefix'),
            first_name: Yup.string().required().label('First name'),
            last_name: Yup.string().required().label('Last name'),
            pan_number: Yup.string(),
        }),
    ),
    children: Yup.array().of(
        Yup.object().shape({
            prefix: Yup.string().required().label('Prefix'),
            first_name: Yup.string().required().label('First name'),
            last_name: Yup.string().required().label('Last name'),
            age: Yup.number().required().label('Age'),
        }),
    ),
})

const CheckoutDetails = () => {
    const {actionsLoading, cartItem, selectedAgent,agentWalletInfo, guestNationality, guestDestinationCountry}: any = useSelector<RootState>(
        (state) => ({
            actionsLoading: state.common.actionsLoading,
            cartItem: state.bookings.cartItem,
            selectedAgent: state.bookings.selectedAgentID,
            agentWalletInfo: state.bookings.agentWalletInfo,
            guestNationality: state.bookings.guestNationality,
            guestDestinationCountry: state.bookings.guestDestinationCountry,
        }),
        shallowEqual,
    )

    const {hotels, sightSeeings, packages, transfers} = cartItem
    const [hotelsInitialData, setHotelsInitialData] = useState<any[]>([])
    const [sightSeeingsInitialData, setSightSeeingsInitialData] = useState<any[]>([])
    const [packagesInitialData, setPackagesInitialData] = useState<any[]>([])
    const [transfersInitialData, setTransfersInitialData] = useState<any[]>([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const totalCartItem = useMemo(
        () =>
            cartItem.hotels.length +
            cartItem.sightSeeings.length +
            cartItem.transfers.length +
            cartItem.packages.length,
        [cartItem],
    )

    useEffect(() => {
        setHotelsInitialData(manageHotelInitialData(hotels))
        setSightSeeingsInitialData(manageSightSeeingInitialData(sightSeeings))
        setTransfersInitialData(manageTransferInitialData(transfers))
        setPackagesInitialData(managePackageInitialData(packages))
    }, [cartItem])
    const initialValues = {
        guest_nationality_id: guestNationality ?? 97,
        destination_country: guestDestinationCountry ?? 208,
        agent_id: selectedAgent,
        agent_ref_no: '',
        type: 'booking',
        lead_passenger: {
            prefix: 'mr',
            first_name: '',
            last_name: '',
            pan_number: '',
            phone: '',
        },
        hotels: hotelsInitialData || [],
        sight_seeings: sightSeeingsInitialData || [],
        packages: packagesInitialData || [],
        transfers: transfersInitialData || [],
    }
    let validateSchema = Yup.object().shape({
        lead_passenger: Yup.object().shape({
            prefix: Yup.string().required().label('Prefix'),
            first_name: Yup.string().required().label('First name'),
            last_name: Yup.string().required().label('Last name'),
            /*pan_number: Yup.string().required().label('Pan number').matches(
                /^[A-Z]{5}[0-9]{4}[A-Z]$/i,
                'Invalid PAN number format. PAN should be in the format AAAAA9999A',
            ),*/
            // phone: Yup.string().required().label('Phone'),
        }),
        // hotels: Yup.array().of(hotelSchema),
        // sight_seeings: Yup.array().of(sightSeeingsSchema),
        // packages: Yup.array().of(sightSeeingsSchema),
        // transfers: Yup.array().of(sightSeeingsSchema),
    })
    // console.log('initialValues', initialValues)
    const backToBookingList = (response: { success: boolean }, type: string) => {
        if (response?.success) {
            dispatch(resetBookingInfo({}))
            if (type === 'booking') {
                navigate(`/bookings`)
            } else {
                navigate(`/quotations`)
            }
        }
    }
    return (
        <React.Fragment>
            {actionsLoading && <Loader/>}
            <Row className={'mb-3 mt-0'}>
                <Col md={12}>
                    <div className={'text-gray-800 fs-5 fw-semibold mb-2'}>
                        {totalCartItem || 0} Item(s) are in Cart
                    </div>
                </Col>
            </Row>
            <Formik
                enableReinitialize={true}
                validationSchema={validateSchema}
                initialValues={initialValues}
                onSubmit={async (values) => {
                    if (values.type === 'booking') {
                        const result = CheckBookingInCancellationPeriod(values, agentWalletInfo)
                        if (result) {
                            const response: any = await dispatch(createBooking(values))
                            backToBookingList(response, values.type)
                        }
                    } else {
                        const response: any = await dispatch(createBooking(values))
                        backToBookingList(response, values.type)
                    }
                }}
            >
                {({values, handleSubmit, setFieldValue, handleChange, handleBlur, errors}) => (
                    <React.Fragment>
                        <ConnectedFocusError/>
                        <Card className={'gutter-b'}>
                            <Card.Body className={'p-2 '}>
                                <Row className={'px-2'}>
                                    <div className='text-danger mt-1 fw-semibold my-2 fs-7'>
                                        LEAD PASSENGER DETAILS
                                    </div>
                                    <Col md={2}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>
                                                Prefix<span className='text-danger'>*</span>
                                            </label>
                                            <Select
                                                options={PrefixOption}
                                                value={PrefixOption.find((prefix) => prefix.value === values.lead_passenger.prefix)}
                                                name='lead_passenger.prefix'
                                                className='react-select-container'
                                                classNamePrefix='react-select'
                                                placeholder='Select a prefix'
                                                onChange={option => {
                                                    let event = {
                                                        target: {
                                                            name: 'lead_passenger.prefix',
                                                            value: option?.value,
                                                        },
                                                    }
                                                    handleChange(event)
                                                    manageLeadPassengerDetails('prefix', option?.value, handleChange, values)
                                                    // setPrefixOption(option)
                                                }}
                                                onBlur={() => {
                                                    handleBlur({target: {name: 'prefix'}})
                                                }}
                                            />
                                            <ErrorMessage
                                                name={`lead_passenger.prefix`}
                                                component='div' className='text-danger mt-2'/>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>
                                                First Name<span className='text-danger'>*</span>
                                            </label>
                                            <Field
                                                name='lead_passenger.first_name'
                                                component={Input}
                                                placeholder={'First name'}
                                                onBlur={(e: {
                                                    target: { value: any }
                                                }) => manageLeadPassengerDetails('first_name', e.target.value, handleChange, values)}
                                            />
                                            <ErrorMessage
                                                name={`lead_passenger.first_name`}
                                                component='div' className='text-danger mt-2'/>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>
                                                Last Name<span className='text-danger'>*</span>
                                            </label>
                                            <Field
                                                name='lead_passenger.last_name'
                                                component={Input}
                                                placeholder={'Last name'}
                                                onBlur={(e: {
                                                    target: { value: any }
                                                }) => manageLeadPassengerDetails('last_name', e.target.value, handleChange, values)}
                                            />
                                            <ErrorMessage
                                                name={`lead_passenger.last_name`}
                                                component='div' className='text-danger mt-2'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={'px-2'}>
                                    <Col md={4}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>
                                                PAN Number{/*<span className='text-danger'>(Mandatory)</span>*/}
                                            </label>
                                            <Field name='lead_passenger.pan_number' component={Input}
                                                   placeholder={'Pan number'}
                                                   onBlur={(e: {
                                                       target: { value: any }
                                                   }) => manageLeadPassengerDetails('pan_number', e.target.value, handleChange, values)}/>
                                            <ErrorMessage
                                                name={`lead_passenger.pan_number`}
                                                component='div' className='text-danger mt-2'/>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>Guest Number</label>
                                            <PhoneInput
                                                buttonClass='flag_dropdown'
                                                dropdownStyle={{backgroundColor: 'none'}}
                                                enableSearch={false}
                                                disableSearchIcon={true}
                                                searchStyle={{width: '97%', margin: '0'}}
                                                countryCodeEditable={true}
                                                disableDropdown={false}
                                                // onlyCountries={['in', 'us']}
                                                country='in'
                                                // value={phone}
                                                inputProps={{
                                                    name: 'lead_passenger.phone',
                                                    id: 'phone',
                                                    required: true,
                                                }}
                                                onChange={(phone: any, data: any) => {
                                                    handleChange(
                                                        {
                                                            target: {name: 'lead_passenger.phone', value: phone},
                                                        })
                                                }}
                                                onBlur={() => {
                                                    handleBlur({target: {name: 'phone'}})
                                                }}
                                            />
                                            <ErrorMessage name='lead_passenger.phone' component='div'
                                                          className='text-danger mt-2'/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={'px-2'}>
                                    <Col md={4}>
                                        <div className={'form-group'}>
                                            <label className='form-label'>Agent Ref No.</label>
                                            <Field name='agent_ref_no' component={Input}
                                                   placeholder={'Agent reference no.'}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {/*--------------------------- For Hotel Check out Start ------------------*/}
                        <Row className={'m-3 mt-0'}>
                            <Col md={12} className={'px-0'}>
                                {hotels && hotels.length !== 0 && (
                                    <div className={'text-gray-800 fs-4 fw-semibold'}>{hotels.length} Hotels:</div>
                                )}
                            </Col>
                        </Row>
                        {hotels &&
                            hotels.length !== 0 &&
                            hotels.map((items: any, index: number) => (
                                <React.Fragment key={`hotelsLoop${index}`}>
                                    <HotelCheckOutDetails
                                        items={items}
                                        index={index}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </React.Fragment>
                            ))}
                        {/*--------------------------- For Hotel Check Out End ------------------*/}
                        {/*--------------------------- For Sight Seeing Start ------------------*/}
                        <Row className={'m-3 mt-0'}>
                            <Col md={12} className={'px-0'}>
                                {sightSeeings && sightSeeings.length !== 0 && (
                                    <div className={'text-gray-800 fs-4 fw-semibold'}>{sightSeeings.length} Sight
                                        Seeings:</div>
                                )}
                            </Col>
                        </Row>
                        {sightSeeings &&
                            sightSeeings.length !== 0 &&
                            sightSeeings.map((items: any, index: number) => (
                                <React.Fragment key={`sightSeeings${index}`}>
                                    <SightSeeingCheckOutDetails
                                        items={items}
                                        index={index}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </React.Fragment>
                            ))}
                        {/*--------------------------- For Sight Seeing End ------------------*/}
                        {/*--------------------------- For Transfer Start ------------------*/}
                        <Row className={'m-3 mt-0'}>
                            <Col md={12} className={'px-0'}>
                                {transfers && transfers.length !== 0 && (
                                    <div
                                        className={'text-gray-800 fs-4 fw-semibold'}>{transfers.length} Transfers:</div>
                                )}
                            </Col>
                        </Row>
                        {transfers &&
                            transfers.length !== 0 &&
                            transfers.map((items: any, index: number) => (
                                <React.Fragment key={`transfers${index + 1}`}>
                                    <TransferCheckOutDetails
                                        items={items}
                                        index={index}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </React.Fragment>
                            ))}
                        {/*--------------------------- For Transfer End ------------------*/}
                        {/*--------------------------- For Package Start ------------------*/}
                        <Row className={'m-3 mt-0'}>
                            <Col md={12} className={'px-0'}>
                                {packages && packages.length !== 0 && (
                                    <div className={'text-gray-800 fs-4 fw-semibold'}>{packages.length} Packages:</div>
                                )}
                            </Col>
                        </Row>
                        {packages &&
                            packages.length !== 0 &&
                            packages.map((items: any, index: number) => (
                                <React.Fragment key={`packages${index + 1}`}>
                                    <PackageCheckOutDetails
                                        items={items}
                                        index={index}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </React.Fragment>
                            ))}
                        {/*--------------------------- For Package End ------------------*/}
                        <Row>
                            <Col className="d-flex">
                                <div className='form-group'>
                                    <button
                                        name='submit'
                                        type='button'
                                        className='btn btn-primary me-2'
                                        onClick={() => {
                                            setFieldValue('type', 'booking')
                                            handleSubmit()
                                        }}
                                    >
                                        Create Booking
                                    </button>
                                </div>
                                <div className='form-group'>
                                    <button
                                        name='submit'
                                        type='button'
                                        className='btn btn-primary me-2'
                                        onClick={() => {
                                            setFieldValue('type', 'quotation')
                                            handleSubmit()
                                        }}
                                    >
                                        Save Quotation
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>

                )}
            </Formik>
        </React.Fragment>
    )
}

export default CheckoutDetails

function DateFormat(props: { items: any }) {
    return <div className={'text-muted'}>{moment(props.items).format('DD/MM/YYYY')}</div>
}
