import {createSlice} from '@reduxjs/toolkit'

const initialCountryState = {
  totalCount: 0,
  countries: null,
  countryDropdown: [],
  nationalityDropdown: [],
  country: undefined,
}

export const CountrySlices = createSlice({
  name: 'countries',
  initialState: initialCountryState,
  reducers: {
    country: (state, action) => {
      state.country = action.payload.country
    },

    countries: (state, action) => {
      state.countries = action.payload.countries
      state.totalCount = action.payload.total_count
    },
    countryDropdown: (state, action) => {
      state.countryDropdown = action.payload.countries
    },
    nationalityDropdown: (state, action) => {
      state.nationalityDropdown = action.payload.nationalities
    },
  },
})