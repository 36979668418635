import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import * as auth_actions from '../../../setup/redux/auth/actions'

export function Logout() {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  useEffect(() => {
    dispatch<any>(auth_actions.logout(navigate))
  }, [navigate, dispatch])
  return <></>
}
