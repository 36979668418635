import {createSlice} from '@reduxjs/toolkit'

const initialLeaveTypes = {
  leaveType: undefined,
  leaveTypesDropdown: null
}

export const LeaveTypeSlices = createSlice({
  name: 'leave_types',
  initialState: initialLeaveTypes,
  reducers: {
    leaveType: (state, action) => {
      state.leaveType = action.payload.leave_type
    },
    leaveTypeList: (state, action) => {
      state.leaveTypesDropdown = action.payload.leave_types;
    }
  },
})