import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {AuthSlices} from '../../../setup/redux/auth/slices'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {RootState} from '../../../setup'
import {PageTitle} from '../../../_metronic/layout/core'
import {Checkbox, Input} from '../../../_metronic/partials/controls'
import {postRequest} from '../../../setup/redux/common/cruds'
import {Loader} from '../../services/Loader'
import {serialize} from 'object-to-formdata'
import {handleNotificationWithToastify} from '../../services/Functional'
import {DeleteListItem} from '../../services/table/DeleteListItem'
import RenderSmoothImage from '../hotels/pages/components/steps/RenderSmoothImage'
import {Col} from 'react-bootstrap'
import {useMutation} from 'react-query'
import {deleteProfileAvatar} from '../../../setup/redux/users/actions'

const initRequest = {
  name: '',
  username: '',
  email: '',
  country_code: '',
  phone: '',
  avatar: undefined,
  is_two_factor_auth: true,
  internal_notes: '',

}
const {actions} = AuthSlices
const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Please enter full name')
    .max(50, 'Max 50 character allowed')
    .matches(/^[A-Z]+\s[A-Z]+(?:['_.\s][a-z]+)*$/i,
      'Full name is not valid. Ex John Doe',
    )
    .required('Full name is required'),
  username: Yup.string()
    .required('User name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  phone: Yup.string()
    .required('Phone is required'),
  avatar: Yup.mixed()
    .test('fileFormat', 'Allow only jpg and png format', (value) => {
      if (!value || typeof value === 'string') {
        return true
      }
      return ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type)
    }),
})


export const UpdateProfile = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const saveRequest = (values: any) => {
    setLoading(true)
    postRequest(`${process.env.REACT_APP_API_BASE_URL}auth/update-profile`, values, {
      'Content-Type': 'multipart/form-data',
    }).then(response => {
      setLoading(false)
      handleNotificationWithToastify(response.data)
      if (response.data.success) {
        dispatch(actions.updateUser(response.data.data))
        navigate('/dashboard')
      }
    }).catch(er => {
      setLoading(false)
    })
  }
  const user: any = useSelector<RootState>(state => state.auth.user)
  const userdata = {
    id: user.id,
    name: user.name,
    username: user.username,
    email: user.email,
    phone: user.phone,
    country_code: user.country_code,
    internal_notes: user.internal_notes,
    avatar: user.avatar,
    is_two_factor_auth: user.is_two_factor_auth,
  }
  const DeleteProfileAvatar = async () => {
    const response: any = await dispatch(deleteProfileAvatar(user.id))
    setLoading(false)
    if (response.success) {
      dispatch(actions.updateAvatarUser({}))
    }
  }
  const deleteItem = useMutation(() => DeleteProfileAvatar())
  return (
    <>
      {loading && <Loader />}
      <PageTitle>Manage Profile</PageTitle>
      <div id='kt_content_container' className='container'>
        <Formik
          enableReinitialize={true}
          initialValues={userdata || initRequest}
          validationSchema={ProfileSchema}
          onSubmit={(values) => {
            saveRequest(serialize(values))
          }}
        >
          {({values, handleSubmit, handleChange, setFieldValue, errors, touched, handleBlur}) => (
            <>
              <Form className='form'>
                <div className='card gutter-b'>
                  <div className='card-body pb-0'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>Full Name <span
                            className='text-danger'>*</span> </label>
                          <Field name='name' component={Input} placeholder='Ex. John Doe' />
                          <ErrorMessage name='name' component='div' className='text-danger mt-2' />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>User Name <span
                            className='text-danger'>*</span></label>
                          <Field name='username' component={Input} placeholder='Enter Username' />
                          <ErrorMessage name='username' component='div' className='text-danger mt-2' />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>Email <span
                            className='text-danger'>*</span></label>
                          <Field type='email' name='email' component={Input} placeholder='mail@website.com' />
                          <ErrorMessage name='email' component='div' className='text-danger mt-2' />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>Phone <span
                            className='text-danger'>*</span></label>
                          <PhoneInput
                            buttonClass='flag_dropdown'
                            dropdownStyle={{backgroundColor: 'none'}}
                            enableSearch={true}
                            disableSearchIcon={true}
                            searchStyle={{width: '97%', margin: '0'}}
                            countryCodeEditable={true}
                            disableDropdown={true}
                            // onlyCountries={['in', 'us']}
                            country='in'
                            value={user.country_code + user.phone}
                            inputProps={{
                              name: 'phone',
                              id: 'phone',
                              required: true,
                            }}
                            onChange={(phone: any, data: any) => {
                              handleChange(
                                {
                                  target: {name: 'country_code', value: data.dialCode},
                                })
                              let phone_number = phone.slice(data.dialCode.length)
                              handleChange(
                                {
                                  target: {name: 'phone', value: phone_number},
                                })
                            }}
                            onBlur={() => {
                              handleBlur({target: {name: 'phone'}})
                            }}
                          />
                          <ErrorMessage name='phone' component='div' className='text-danger mt-2' />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <div className='d-flex justify-content-between'>
                            <label className='form-label'
                                   htmlFor='logo'>Avatar</label>
                          </div>
                          <input type='file' className='form-control' id='avatar'
                                 name='avatar' accept='image/*'
                                 onChange={(event) => {
                                   // @ts-ignore
                                   setFieldValue('avatar', event.target.files[0])
                                 }} />
                          <ErrorMessage name='avatar' component='div' className='text-danger mt-2' />
                        </div>
                        {values.avatar && typeof values.avatar === 'string' &&
                        <Col md={2}>
                          <div className={'position-relative docItembox'}>
                            <div className={'removeDocitem'}><i className='bi bi-x'
                                                                onClick={async () => {
                                                                  await DeleteListItem(deleteItem, setLoading)
                                                                }} />
                            </div>
                            <a href={process.env.REACT_APP_AWS_URL + values.avatar} rel='noreferrer' target='_blank'>
                              <RenderSmoothImage src={process.env.REACT_APP_AWS_URL + values.avatar}
                                                 extraClassName={'img-thumbnail'} />
                            </a>

                          </div>
                        </Col>}
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>Two Factor Auth</label>
                          <Field component={Checkbox} value='1'
                                 isSelected={values.is_two_factor_auth}
                                 name='is_two_factor_auth' />
                        </div>
                      </div>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <label className='form-label'>Internal Notes</label>
                          <Field name='internal_notes' as='textarea'
                                 className='form-control'
                                 rows={3} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card gutter-b'>
                  <div className='card-body pb-0'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='form-group'>
                          <button type='submit' onSubmit={() => handleSubmit}
                                  className='btn btn-primary'>Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}
