export function manageSightSeeingInitialData(sightSeeings: any) {
    const sightSeeingsInitialData: {
        sight_seeing_id: number,
        pickup_date: string,
        pickup_hotel: number,
        system_price: number,
        sell_price: number,
        per_adult_price: number,
        per_child_price: number,
        purchase_price_usd: number,
        purchase_price_original: number,
        conversion_rate: number,
        combination: [],
        pickup_time: string,
        start_time: string,
        end_time: string,
        type: string,
        category: string,
        availability: boolean,
        cancel_upto: string,
        special_request: string,
        adults: { prefix: string; first_name: string; last_name: string; pan_number: string }[],
        children: { prefix: string; first_name: string; last_name: string; age: any }[]
    }[] = []
    if (sightSeeings && sightSeeings.length) {
        sightSeeings.map((sightSeeing: any, sightSeeingIndex: number) => {
            const adultsInitialData = []
            const childInitialData = []
            for (let i = 0; i < sightSeeing.searchData.adult; i++) {
                adultsInitialData.push({
                    prefix: 'mr',
                    first_name: '',
                    last_name: '',
                    pan_number: '',
                })
            }
            for (let i = 0; i < sightSeeing.searchData.child_count; i++) {
                childInitialData.push({
                    prefix: 'mr',
                    first_name: '',
                    last_name: '',
                    age: sightSeeing.searchData.child[i].age,
                })
            }
            sightSeeingsInitialData.push({
                sight_seeing_id: sightSeeing.sight_seeing.id,
                pickup_date: sightSeeing.searchData.pickup_date,
                pickup_hotel: sightSeeing.hotel_data.id,
                system_price: sightSeeing.price,
                sell_price: sightSeeing.price,
                per_adult_price: sightSeeing.adult_price,
                per_child_price: sightSeeing.child_price,
                purchase_price_usd: sightSeeing.purchase_price_usd,
                purchase_price_original: sightSeeing.purchase_price_original,
                conversion_rate: sightSeeing.conversion_rate,
                combination: sightSeeing?.combination,
                pickup_time: sightSeeing.pickup_time,
                start_time: sightSeeing.start_time,
                end_time: sightSeeing.end_time,
                type: sightSeeing.type,
                category: sightSeeing.category,
                availability: sightSeeing?.sight_seeing.availability,
                cancel_upto: sightSeeing?.sight_seeing.cancel_upto,
                special_request: '',
                adults: adultsInitialData,
                children: childInitialData
            })
        })
    }
    return sightSeeingsInitialData;
}