import {createSlice} from '@reduxjs/toolkit'

const initialCityState = {
  totalCount: 0,
  cities: null,
  cityDropdown: [],
  supplierCityDropdown: [],
  city: undefined,
}

export const CitySlices = createSlice({
  name: 'cities',
  initialState: initialCityState,
  reducers: {
    city: (state, action) => {
      state.city = action.payload.city
    },

    cities: (state, action) => {
      state.cities = action.payload.cities
      state.totalCount = action.payload.total_count
    },
    cityDropdown: (state, action) => {
      state.cityDropdown = action.payload.cities
    },
    supplierCityDropdown: (state, action) => {
      state.supplierCityDropdown = action.payload.cities
    },
  },
})