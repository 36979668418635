import * as requestFromServer from '../common/cruds'
import {ConfigurationSlices} from './slices'
import {callTypes, catchError, endCall, startCall} from '../common/slices'

const actions = ConfigurationSlices.actions
const API_URL = `${process.env.REACT_APP_API_BASE_URL}configurations/`

export const getConfiguration = () => (dispatch:any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .getRequest(API_URL)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      dispatch(actions.configurations(response.data.data))
      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find configuration'
      dispatch(catchError({error, callType: callTypes.action}))
      return error
    })
}


export const saveConfigurationData = (payload: any) => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .postRequest(API_URL, payload, {
      'Content-Type': 'multipart/form-data',
    })
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t create site'
      dispatch(catchError({error, callType: callTypes.action}))
      return error
    })
}

export const getCurrencies = () => (dispatch: any) => {
  return requestFromServer
    .getRequest(`${process.env.REACT_APP_API_BASE_URL}currencies`)
    .then(response => {
      dispatch(actions.currencies(response.data.data))
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find country'
      dispatch(catchError({error, callType: callTypes.action}))
    })
}