import React, {useEffect} from 'react'
import * as actions from '../../../setup/redux/attendances/actions'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import moment from 'moment/moment'
import {checkEmpty} from '../../services/Functional'

type Props = {
  className: string
}

export const AttendanceTable: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.checkTodayAttendance())
  }, [dispatch])
  const {todayAttendance}: any = useSelector<RootState>(
    (state) => ({
      todayAttendance: state.attendances.todayAttendance,
    }), shallowEqual)
  const userCheckOut = async () => {
    const payload = {
      check_out: moment().format('HH:mm:ss'),
    }
    await dispatch(actions.checkOutAttendance(payload))
  }
  const userCheckIn = async () => {
    const payload = {
      date: new Date(),
      check_in: moment().format('HH:mm:ss'),
    }
    await dispatch(actions.checkInAttendance(payload))
  }
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Today's Attendance</span>
        </h3>
        <div className='card-toolbar'>
          {todayAttendance?.is_check_in
            ? <button className='btn btn-danger btn--glow' onClick={userCheckOut}>Check
              Out</button>
            : <button className='btn btn-primary btn--glow' onClick={userCheckIn}>Check
              In</button>}
        </div>
      </div>
      <div className='card-body py-3'>
        {todayAttendance?.today_attendances?.length > 0
          ?
          <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Check In</th>
                <th className='min-w-140px'>Check Out</th>
                <th className='min-w-120px'>Hours</th>
              </tr>
              </thead>
              <tbody>
              {todayAttendance?.today_attendances?.map((attendance: any, index: number) => (
                <tr key={`attendance_${index + 1}`}>
                  <th>{moment(attendance?.check_in, '"HH:mm:ss"').format('HH:mm A')}</th>
                  <th>{attendance?.check_out ? moment(attendance?.check_out, '"HH:mm:ss"').format('HH:mm A') : '-'}</th>
                  <th>{checkEmpty(attendance.hours)}
                  </th>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          : <p id='attendanceDetails'>No attendance recorded yet.</p>}
      </div>
    </div>
  )
}