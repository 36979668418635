import React, {useMemo} from 'react'
import {Button, Card, Col, Row} from 'react-bootstrap'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {Link} from 'react-router-dom'

const TotalCheckOutPrice = () => {
  const {actionsLoading, cartItem}: any = useSelector<RootState>(
    (state) => ({
      actionsLoading: state.common.actionsLoading,
      cartItem: state.bookings.cartItem,
    }),
    shallowEqual
  )

  const totalAmount = useMemo(
    () =>
      cartItem.hotels?.reduce((total: number, hotel: any) => {
        const roomPrices = hotel.room_info.map((room: {roomData: {price: string}}) => parseFloat(room.roomData.price))
        const hotelTotalPrice = roomPrices.reduce((roomAcc: any, roomPrice: any) => roomAcc + roomPrice, 0)
        return total + hotelTotalPrice
      }, 0) +
      cartItem.sightSeeings?.reduce(
        (total: number, item: any) => total + parseFloat(item?.price),
        0,
      ) +
      cartItem.packages?.reduce((total: number, item: any) => total + parseFloat(item?.price), 0)+
      cartItem.transfers?.reduce((total: number, item: any) => total + parseFloat(item?.price), 0),
    [cartItem],
  )
  return (
    <React.Fragment>
      <Card className={'gutter-b'}>
        <Card.Body>
          <Row className={'mb-2'}>
            <Col md={12}>
              <h2>Total</h2>
              <div className={'fs-2 text-danger fw-semibold'}>USD: {totalAmount.toFixed(2)}</div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Link to={'/checkout/details'}>
                <Button size={'lg'} className={'mb-2 w-100 px-2'}>
                  Processed Next
                </Button>
              </Link>
            </Col>
            <Col md={12}>
              <Link to={'/quotations/create'} className={'mb-2 px-2 btn btn-sm btn-danger w-100'}>
                Add More Services
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export {TotalCheckOutPrice}
