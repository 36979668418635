import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {postRequest} from '../../../../setup/redux/common/cruds'
import Swal from 'sweetalert2'
import {Loader} from '../../../services/Loader'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {AuthFooter} from '../../../services/Functional'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const initialValues = {
  token: '',
  email: '',
  password: '',
  confirm_password: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password does not match')
    .required('Confirm password is required'),
})

export function ResetPassword() {
  const params: any = useParams()
  let {tokenDetail} = params;
  tokenDetail = atob(tokenDetail);
  const data = JSON.parse(tokenDetail);
  const {email, token} = data;
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {config}: any = useSelector<RootState>(
    (state) => ({
      config: state.configuration.configurations,
    }),
    shallowEqual,
  )
  React.useEffect(() => {
    setLoading(true)
    postRequest(
      `${process.env.REACT_APP_API_BASE_URL}auth/validate-reset-password-token`,
      data,
    ).then(response => {
      setLoading(false)
      if (!response.data.success) {
        Swal.fire({
          title: '',
          html: response.data.message,
          icon: 'warning',
          allowOutsideClick: false,
        }).then(() => {
          Swal.close()
          navigate('/auth/login')
        })
      }
    })
  }, [])
  const [hasErrors, setHasErrors] = useState(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      let payload: any = {
        email,
        token,
        password: values.password,
        confirm_password: values.confirm_password,
      }
      postRequest(`${process.env.REACT_APP_API_BASE_URL}auth/reset-password`, payload)
        .then(response => {
          setLoading(false)
          Swal.fire({
            title: response.data.success ? 'Success' : 'Warning',
            html: response.data.message,
            icon: response.data.success ? 'success' : 'warning',
            allowOutsideClick: false,
          }).then(() => {
            navigate('/auth/login')
            Swal.close()
          })
        })
    },
  })

  return (
    <>
      {loading && <Loader />}
      <div className="row w-100 h-100 p-0 m-0">
        <div className="col-md-6 background_card">
          <img src={toAbsoluteUrl("/media/actual_logo.png")} alt="" />
        </div>
        <div className="col-md-6 p-0 bg-body">
          <div className="login_card">
            <div className="login_form">
              <form
                className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                noValidate
                id="kt_login_password_reset_form"
                onSubmit={formik.handleSubmit}
              >
                <div className="text-center mb-10 animate__animated animate__fadeInUp">
                  <h1 className="text-gray-800 mb-3">Set New Password</h1>
                  <div className="fs-4">
                    Enter your password and confirm password.
                  </div>
                </div>

                {hasErrors === true && (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      Sorry, looks like there are some errors detected, please
                      try again.
                    </div>
                  </div>
                )}

                {hasErrors === false && (
                  <div className="mb-10 bg-light-info p-8 rounded">
                    <div className="text-info">Password set successfully.</div>
                  </div>
                )}

                <div className="fv-row mb-10 animate__animated animate__fadeInUp">
                  <label className="font-size-h6 mb-0">Password *</label>
                  <input
                    type="password"
                    placeholder=""
                    autoComplete="off"
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg custom_input',
                      {
                        'is-invalid':
                          formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid':
                          formik.touched.password && !formik.errors.password,
                      },
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="fv-row mb-10 animate__animated animate__fadeInUp">
                  <label className="font-size-h6 mb-0">
                    Confirm Password *
                  </label>
                  <input
                    type="password"
                    placeholder=""
                    autoComplete="off"
                    {...formik.getFieldProps('confirm_password')}
                    className={clsx(
                      'form-control form-control-lg custom_input',
                      {
                        'is-invalid':
                          formik.touched.confirm_password &&
                          formik.errors.confirm_password,
                      },
                      {
                        'is-valid':
                          formik.touched.confirm_password &&
                          !formik.errors.confirm_password,
                      },
                    )}
                  />
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.confirm_password}
                          </span>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="d-flex flex-wrap justify-content-center pb-lg-0 animate__animated animate__fadeInUp">
                  <button
                    type="submit"
                    id="kt_password_reset_submit"
                    className="btn btn-lg btn-primary fw-bolder me-4"
                  >
                    <span className="indicator-label">Submit</span>
                    {loading && (
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    )}
                  </button>
                  <Link to="/auth/login">
                    <button
                      type="button"
                      id="kt_login_password_reset_form_cancel_button"
                      className="btn btn-lg btn-light-primary fw-bolder"
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      Cancel
                    </button>
                  </Link>{' '}
                </div>
                <AuthFooter />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}