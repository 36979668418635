import React, {createContext, FC, ReactNode, useContext, useState} from 'react';

// Define the context type
interface QueryManageListStatusType {
    currentPage: number;
    currentSearch: string;
    startDate: string;
    endDate: string;
    createDate: string;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setCurrentSearch: React.Dispatch<React.SetStateAction<string>>;
    setStartDate: React.Dispatch<React.SetStateAction<string>>;
    setEndDate: React.Dispatch<React.SetStateAction<string>>;
    setCreateDate: React.Dispatch<React.SetStateAction<string>>;
}

// Create the context with a default value
const QueryManageListStatus = createContext<QueryManageListStatusType>({
    currentPage: 1,
    currentSearch: '',
    startDate: '',
    endDate: '',
    createDate: '',
    setCurrentPage: () => {
    },
    setCurrentSearch: () => {
    },
    setStartDate: () => {
    },
    setEndDate: () => {
    },
    setCreateDate: () => {
    },
});

interface ManageListStatusProps {
    children: ReactNode;
}

// Context provider component
const ManageListStatus: FC<ManageListStatusProps> = ({children}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentSearch, setCurrentSearch] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [createDate, setCreateDate] = useState<string>('');

    return (
        <QueryManageListStatus.Provider
            value={{currentPage, currentSearch, startDate, endDate, createDate, setCurrentPage, setCurrentSearch, setStartDate, setEndDate, setCreateDate}}>
            {children}
        </QueryManageListStatus.Provider>
    );
};

// Custom hook to use the context
const useManageListStatus = () => useContext(QueryManageListStatus);

export {ManageListStatus, useManageListStatus};
