/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import React from 'react'
import {ResetPassword} from './components/ResetPassword'
import {Otp} from './components/Otp'


const AuthPage = () => (
  <Routes>
    <Route>
      <Route path='login' element={<Login />} />
      <Route path='otp' element={<Otp />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='set-password/:tokenDetail' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
