import React, {useState} from 'react'
import {Spinner} from 'react-bootstrap'

const RenderSmoothImage = (props: {src: string, extraClassName: string}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  return (
    <React.Fragment>
      <div className='spinnerCenter'>
        <img
          src={props.src}
          alt='S3 ImageData'
          className={`${props.extraClassName} smoothImageRender ${imageLoaded ? 'imageRenderVisible' : 'imageRenderHidden'}`}
          onLoad={() => setImageLoaded(true)} />
        {!imageLoaded && (
          <Spinner animation='border' variant='primary' />
        )}
      </div>
    </React.Fragment>
  )
}


export default RenderSmoothImage