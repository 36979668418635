import {createSlice} from '@reduxjs/toolkit'

const initialAirportState = {
  totalCount: 0,
  airports: null,
  airport: undefined,
  airportDropdown: [],
  airportDropdownCountry: [],
  airportDropdownCityFrom: [],
  airportDropdownCityTo: [],
}

export const AirportSlices = createSlice({
  name: 'airports',
  initialState: initialAirportState,
  reducers: {
    airport: (state, action) => {
      state.airport = action.payload.airport
    },

    airports: (state, action) => {
      state.airports = action.payload.airports
      state.totalCount = action.payload.total_count
    },

    airportDropdown: (state, action) => {
      if (action.payload.type === 'city_from') {
        state.airportDropdownCityFrom = action.payload.data.airports
      } else {
        state.airportDropdownCityTo = action.payload.data.airports
      }
    },

    airportDropdownCountry: (state, action) => {
      state.airportDropdownCountry = action.payload.data.airports
    },
  },
})