import {createSlice} from '@reduxjs/toolkit'

const initialRoomAmenityState = {
  totalCount: 0,
  room_amenities: null,
  room_amenity: undefined,
  room_amenities_dropdown: []
}

export const RoomAmenitySlices = createSlice({
  name: 'room_amenities',
  initialState: initialRoomAmenityState,
  reducers: {
    room_amenity: (state, action) => {
      state.room_amenity = action.payload.room_amenity
    },

    room_amenities: (state, action) => {
      state.room_amenities = action.payload.room_amenities
      state.totalCount = action.payload.total_count
    },

    room_amenities_dropdown: (state, action) => {
      state.room_amenities_dropdown = action.payload.room_amenities
    },
  },
})