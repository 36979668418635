import React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Loader} from '../../../services/Loader'
import {CustomForm} from './CustomForm'
import {Row} from 'react-bootstrap'

export const ManageCheckOut = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {actionsLoading, cartItem}: any = useSelector<RootState>(
    (state) => ({
      actionsLoading: state.common.actionsLoading,
      cartItem: state.bookings.cartItem,
    }),
    shallowEqual
  )
  return (
    <React.Fragment>
      {actionsLoading && <Loader />}
      <Row>
        <CustomForm cartItem={cartItem} />
      </Row>
    </React.Fragment>
  )
}