import {handleNotificationPrompt} from "../../../services/Functional";
import moment from "moment";

export function CheckBookingInCancellationPeriod(values: any, agentWallet: any) {
    let totalBookingAmount = 0;
    if (values && values.hotels) {
        for (const hotel of values.hotels) {
            for (const room of hotel.rooms) {
                if (!room.availability) {
                    totalBookingAmount += +room.sell_price;
                }
            }
        }
    }
    if (totalBookingAmount > 0) {
        if (agentWallet.agent_type === 'Credit') {
            if ((agentWallet.credit_limit - agentWallet?.wallet?.balance) >= totalBookingAmount) {
                return true;
            }
        } else {
            if (agentWallet?.wallet?.balance >= totalBookingAmount) {
                return true;
            }
        }
        handleNotificationPrompt('Unable to Process', 'Unable to Process this Service, Booking is Non-Refundable, Kindly Add Balance to Process this Service', 'error')
        return false;
    } else
        return true
}

export function CheckProcessedBookingHasCancellationPeriod(values: any) {
    let totalBookingAmount = 0;
    let today = moment().startOf('day');
    if (values && values.booking_hotels) {
        for (const hotel of values.booking_hotels) {
            for (const room of hotel.rooms) {
                const compareDate = moment(room?.cancel_upto, 'YYYY-MM-DD');
                if (!compareDate.isSameOrAfter(today)) {
                    totalBookingAmount += +room.sell_price;
                }
            }
        }
    }

    if (totalBookingAmount > 0) {
        if (values?.agent?.agent_type === 'Credit') {
            if ((values?.agent?.credit_limit - values?.agent?.wallet?.balance) >= totalBookingAmount) {
                return true;
            }
        } else {
            if (values?.agent?.wallet?.balance >= totalBookingAmount) {
                return true;
            }
        }
        handleNotificationPrompt('Unable to Process', 'Unable to Process this Service, Booking is Non-Refundable, Kindly Add Balance to Process this Service', 'error')
        return false;
    } else
        return true
}

/*
export function CheckBookingInCancellationPeriod(availability: boolean, price: number, agentWallet: any, cartItem: any) {
    if (!availability) {
        let totalBookingAmount = +price;
        if (cartItem && cartItem.hotels) {
            for (const hotel of cartItem.hotels) {
                for (const room of hotel.room_info) {
                    if (!room.roomData.availability) {
                        totalBookingAmount += +room.roomData.price;
                    }
                }
            }
        }
        if (agentWallet.agent_type === 'Credit') {
            if (agentWallet.credit_limit >= totalBookingAmount) {
                return true;
            }
        } else {
            if (agentWallet.wallet >= totalBookingAmount) {
                return true;
            }
        }
        handleNotificationPrompt('Unable to Process', 'Unable to Process this Service, Booking is Non-Refundable, Kindly Add Balance to Process this Service', 'error')
        return false;
    }
    return true;
}*/
