import React, {FC, useEffect, useMemo, useRef} from 'react'
import {SearchComponent} from '../../../assets/ts/components'
import {Badge, Col, Row} from 'react-bootstrap'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {
    addToCartHotels,
    addToCartPackages,
    addToCartSightSeeing,
    addToCartTransfers,
} from '../../../../setup/redux/bookings/slices'
import {NavLink} from 'react-router-dom'
import moment from 'moment/moment'
import {checkEmpty, DateFormat, getRoomInclusion} from '../../../../app/services/Functional'
import DummyHotel from '../../../assets/hotel-colour.jpg'
import RenderSmoothImage from '../../../../app/modules/hotels/pages/components/steps/RenderSmoothImage'

const BookingCart: FC = () => {
    const dispatch = useDispatch()
    const element = useRef<HTMLDivElement | null>(null)
    const wrapperElement = useRef<HTMLDivElement | null>(null)

    const {actionsLoading, cartItem}: any = useSelector<RootState>(
        (state) => ({
            actionsLoading: state.common.actionsLoading,
            cartItem: state.bookings.cartItem,
        }),
        shallowEqual,
    )

    useEffect(() => {
        // Initialize search handler and it's important for toggle cart items
        const searchObject = SearchComponent.createInsance('#kt_header_search')
    }, [])

    const RemoveHotelItem = (index: number) => {
        let dataArray = [...cartItem.hotels]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartHotels(dataArray))
        }
    }
    const RemovePackageItem = (index: number) => {
        let dataArray = [...cartItem.packages]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartPackages(dataArray))
        }
    }
    const RemoveSightSeeingItem = (index: number) => {
        let dataArray = [...cartItem.sightSeeings]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartSightSeeing(dataArray))
        }
    }
    const RemoveTransferItem = (index: number) => {
        let dataArray = [...cartItem.transfers]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartTransfers(dataArray))
        }
    }

    const totalAmount = useMemo(
        () =>
            cartItem.hotels?.reduce((total: number, hotel: any) => {
                const roomPrices = hotel.room_info.map((room: {
                    roomData: { price: string }
                }) => parseFloat(room.roomData.price))
                const hotelTotalPrice = roomPrices.reduce((roomAcc: any, roomPrice: any) => roomAcc + roomPrice, 0)
                return total + hotelTotalPrice
            }, 0) +
            cartItem.sightSeeings?.reduce(
                (total: number, item: any) => total + parseFloat(item?.price),
                0,
            ) +
            cartItem.packages?.reduce((total: number, item: any) => total + parseFloat(item?.price), 0)
            +
            cartItem.transfers?.reduce((total: number, item: any) => total + parseFloat(item?.price), 0)
        ,
        [cartItem],
    )
    const totalCartItem = useMemo(
        () =>
            cartItem.hotels.length +
            cartItem.sightSeeings.length +
            cartItem.transfers.length +
            cartItem.packages.length,
        [cartItem],
    )

    return (
        <React.Fragment>
            <div
                id='kt_header_search'
                className='d-flex align-items-stretch'
                data-kt-search-layout='menu'
                data-kt-menu-trigger='auto'
                data-kt-menu-overflow='false'
                data-kt-menu-permanent='true'
                data-kt-menu-placement='bottom-end'
                ref={element}
            >
                <div
                    className='d-flex align-items-center'
                    data-kt-search-element='toggle'
                    id='kt_header_search_toggle'
                >
                    <div
                        className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px position-relative'>
                        <i className='las la-shopping-cart fs-2x'/>
                        {cartItem && (
                            <Badge
                                pill={true}
                                className={'position-absolute topbar-badge translate-middle'}
                                text={'light'}
                            >
                                {totalCartItem}
                                {/*{cartItem.hotels.length + cartItem.sightSeeings.length + cartItem.transfers.length + cartItem.packages.length}*/}
                            </Badge>
                        )}
                    </div>
                </div>

                <div
                    data-kt-search-element='content'
                    className='menu menu-sub menu-sub-dropdown p-7 w-325px w-md-550px'
                >
                    <div className='main' ref={wrapperElement} data-kt-search-element='wrapper'>
                        <form
                            data-kt-search-element='form'
                            className='w-100 position-relative mb-3 d-none'
                            autoComplete='off'
                        >
                            <input type='text' data-kt-search-element='input'/>
                        </form>
                        <div className='mb-1' data-kt-search-element='main'>
                            <div className='d-flex flex-stack fw-bold mb-2'>
                                <span className='text-muted fs-6 me-2'>Shopping Cart:</span>
                            </div>
                            <div className='scroll-y mh-200px mh-lg-325px '>
                                {cartItem?.hotels?.length !== 0 && (
                                    <div className={'text-muted fs-5  border-bottom mb-2'}>Hotels</div>
                                )}
                                {cartItem &&
                                    cartItem?.hotels?.length !== 0 &&
                                    cartItem.hotels?.map((item: any, index: number) => (
                                        <React.Fragment key={`CartItemHotels${index + 1}`}>
                                            <Row className='align-items-center mb-5 mx-0'>
                                                <Col md={8} className='d-flex px-0'>
                                                    <RenderSmoothImage
                                                        src={
                                                            item?.image ? process.env.REACT_APP_AWS_URL + item?.hotel?.logo : DummyHotel
                                                        }
                                                        extraClassName={'img-fluid rounded h-50px w-75px'}
                                                    />
                                                    <div className={'ps-2'}>
                                                        <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                                                            {item?.hotel.name}
                                                        </div>
                                                        {item?.room_info?.map((room: any, index: number) => (
                                                            <div key={`cartItemRooms${index + 1}`}
                                                                 className='fs-7 text-muted fw-bold'>{room?.roomData?.name} - {getRoomInclusion(room.roomData.room_inclusion)}
                                                                ({room?.searchData?.adult} | {room?.searchData?.child_count})
                                                                {!room?.roomData?.availability && <><br/><span className="text-danger"> (On Request)</span></>}
                                                            </div>
                                                        ))}
                                                        <div className='fs-7 text-muted fw-bold d-flex gap-2'>
                                                            <DateFormat
                                                                items={item?.booking_info?.check_in}/> - <DateFormat
                                                            items={item?.booking_info?.check_out}/></div>
                                                    </div>
                                                </Col>
                                                <Col md={3} className={'fs-6 text-end  fw-bold'}>
                                                    USD: {item?.room_info?.reduce((total: number, room: any) => total + parseFloat(room?.roomData
                                                    .price), 0).toFixed(2)}
                                                </Col>
                                                <Col
                                                    md={1}
                                                    className={'fw-bold'}
                                                    role={'button'}
                                                    onClick={() => RemoveHotelItem(index)}
                                                >
                                                    <i className='las la-trash fs-2x text-hover-danger me-2'/>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                {cartItem?.sightSeeings?.length !== 0 && (
                                    <div className={'text-muted fs-5 border-bottom my-2'}>Sight Seeing</div>
                                )}
                                {cartItem &&
                                    cartItem?.sightSeeings?.length !== 0 &&
                                    cartItem.sightSeeings.map((item: any, index: number) => (
                                        <React.Fragment key={`CartItemsSightSeeings${index}`}>
                                            <Row className='align-items-center mb-5 mx-0'>
                                                <Col md={8} className='d-flex px-0'>
                                                    <RenderSmoothImage
                                                        src={
                                                            item?.sight_seeing?.logo ? process.env.REACT_APP_AWS_URL + item?.sight_seeing?.logo : DummyHotel
                                                        }
                                                        extraClassName={'img-fluid rounded h-50px w-75px'}
                                                    />
                                                    <div className={'ps-2'}>
                                                        <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                                                            {item?.sight_seeing?.name}
                                                        </div>
                                                        <div className='fs-7 text-muted fw-bold d-flex gap-2'>
                                                            {checkEmpty(item?.category)}-({checkEmpty(item?.type)}
                                                            {<DateFormat items={item?.searchData?.pickup_date}/>}
                                                            {checkEmpty(moment(item?.pickup_time, '"HH:mm:ss"').format('HH:mm A'))})
                                                        </div>
                                                        {!item.sight_seeing.availability && <span className="text-danger"> (On Request)</span>}
                                                    </div>
                                                </Col>
                                                <Col md={3} className={'fs-6 text-end  fw-bold'}>
                                                    USD: {item?.price || 0}
                                                </Col>
                                                <Col
                                                    md={1}
                                                    className={'fw-bold '}
                                                    role={'button'}
                                                    onClick={() => RemoveSightSeeingItem(index)}
                                                >
                                                    <i className='las la-trash fs-2x text-hover-danger me-2'/>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                {cartItem?.transfers?.length !== 0 && (
                                    <div className={'text-muted fs-5  border-bottom my-2'}>Transfers</div>
                                )}
                                {cartItem &&
                                    cartItem?.transfers?.length !== 0 &&
                                    cartItem.transfers?.map((item: any, index: number) => (
                                        <React.Fragment key={`CartItemTransfers${index + 1}`}>
                                            <Row className='align-items-start mb-5 mx-0'>
                                                <Col md={8} className='d-flex px-0'>
                                                    <RenderSmoothImage
                                                        src={
                                                            item?.transfer_response?.logo ? process.env.REACT_APP_AWS_URL + item?.transfer_response?.logo : DummyHotel
                                                        }
                                                        extraClassName={'img-fluid rounded h-50px w-75px'}
                                                    />
                                                    <div className={'ps-2'}>
                                                        <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                                                            <span>{item?.transfer_response.name}</span>
                                                        </div>
                                                        <span className='fs-7 text-muted fw-bold d-flex gap-2'>
                                                            Type: <span
                                                            className="text-capitalize"> {item?.searchData?.transfer_type}</span>
                                                            Date: {<DateFormat
                                                            items={item?.searchData?.transfer_date}/>}
                                                          </span>
                                                        {!item.transfer_response.availability && <span className="text-danger"> (On Request)</span>}
                                                    </div>
                                                </Col>
                                                <Col md={3} className={'fs-6 text-end  fw-bold'}>
                                                    USD: {item?.price}
                                                </Col>
                                                <Col
                                                    md={1}
                                                    className={'fw-bold'}
                                                    role={'button'}
                                                    onClick={() => RemoveTransferItem(index)}
                                                >
                                                    <i className='las la-trash fs-2x text-hover-danger '/>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                {cartItem?.packages?.length !== 0 && (
                                    <div className={'text-muted fs-5  border-bottom my-2'}>Packages</div>
                                )}
                                {cartItem &&
                                    cartItem?.packages?.length !== 0 &&
                                    cartItem.packages?.map((item: any, index: number) => (
                                        <React.Fragment key={`CartItemPackages${index + 1}`}>
                                            <Row className='align-items-start mb-5 mx-0'>
                                                <Col md={8} className='d-flex px-0'>
                                                    <RenderSmoothImage
                                                        src={
                                                            item?.package_response?.logo ? process.env.REACT_APP_AWS_URL + item?.package_response?.logo : DummyHotel
                                                        }
                                                        extraClassName={'img-fluid rounded h-50px w-75px'}
                                                    />
                                                    <div className={'ps-2'}>
                                                        <div className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                                                            <span>{item?.package_name}</span>
                                                        </div>
                                                        <span className='fs-7 text-muted fw-bold'>
                            {item?.package_type?.replace('_', ' ')}
                          </span>
                                                        <span className='fs-7 text-muted fw-bold'>
                                                            <DateFormat items={item?.searchData?.pickup_date}/>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <Col md={3} className={'fs-6 text-end  fw-bold'}>
                                                    USD: {item?.price}
                                                </Col>
                                                <Col
                                                    md={1}
                                                    className={'fw-bold'}
                                                    role={'button'}
                                                    onClick={() => RemovePackageItem(index)}
                                                >
                                                    <i className='las la-trash fs-2x text-hover-danger '/>
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                {totalCartItem === 0 && <div className={'text-muted'}>Shopping Cart is Empty </div>}
                            </div>
                            {totalCartItem !== 0 && (
                                <div
                                    className={'d-flex align-items-center justify-content-between border-top pt-3'}
                                >
                                    <div className={'fw-bold fs-5'}>Total: {totalAmount.toFixed(2)}</div>
                                    <NavLink to={'/checkout'} className={'btn btn-primary btn-sm'}>
                                        View Cart
                                    </NavLink>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export {BookingCart}
