import {createSlice} from '@reduxjs/toolkit'

const initialSupplierState = {
  totalCount: 0,
  suppliers: null,
  currenciesDropdown: [],
  supplier: undefined,
}

export const SupplierSlices = createSlice({
  name: 'suppliers',
  initialState: initialSupplierState,
  reducers: {
    supplier: (state, action) => {
      state.supplier = action.payload.supplier
    },

    suppliers: (state, action) => {
      state.suppliers = action.payload.suppliers
      state.totalCount = action.payload.total_count
    },
    currencyDropdown:
      (state, action) => {
        state.currenciesDropdown = action.payload.currencies
      },
  },
})