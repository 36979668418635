import {createSlice} from '@reduxjs/toolkit'

const initialTransferState = {
    totalCount: 0,
    transfers: null,
    transfer: undefined,
    transfer_show: undefined,
    transferPictures: undefined,
    bookingSearchTransfer: [],
    bookingSearchTransferCount: 0
}

export const TransferSlices = createSlice({
    name: 'transfers',
    initialState: initialTransferState,
    reducers: {
        transfer: (state, action) => {
            state.transfer = action.payload.transfer
        },

        transfer_show: (state, action) => {
            state.transfer_show = action.payload.transfer
        },

        transfers: (state, action) => {
            state.transfers = action.payload.transfers
            state.totalCount = action.payload.total_count
        },
        transferPictures: (state, action) => {
            state.transferPictures = action.payload
        },

        transfersByBookingSearch: (state, action) => {
            state.bookingSearchTransfer = action.payload.data.transfers
            state.bookingSearchTransferCount = action.payload.data.total_count
        },
    },
})

export const {transfersByBookingSearch} = TransferSlices.actions