import {createSlice} from '@reduxjs/toolkit'

const initialAttendanceState = {
  totalCount: 0,
  attendances: null,
  attendance: undefined,
  todayAttendance: {
    today_attendances: [],
    is_check_in: false,
  },
  attendancesCalenderData: [],
}

export const AttendanceSlices = createSlice({
  name: 'attendances',
  initialState: initialAttendanceState,
  reducers: {
    attendance: (state, action) => {
      state.attendance = action.payload.attendance
    },

    attendances: (state, action) => {
      state.attendances = action.payload.attendances
      state.totalCount = action.payload.total_count
    },

    todayAttendance: (state, action) => {
      state.todayAttendance = action.payload
    },

    attendancesCalenderData: (state, action) => {
      state.attendancesCalenderData = action.payload.attendances
    },
  },
})