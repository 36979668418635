import {createSlice} from '@reduxjs/toolkit'

const initialPierState = {
  totalCount: 0,
  piers: null,
  pier: undefined,
  pierDropdownCityFrom: [],
  pierDropdownCityTo: [],
}

export const PierSlices = createSlice({
  name: 'piers',
  initialState: initialPierState,
  reducers: {
    pier: (state, action) => {
      state.pier = action.payload.pier
    },

    piers: (state, action) => {
      state.piers = action.payload.piers
      state.totalCount = action.payload.total_count
    },

    pierDropdown: (state, action) => {
      if(action.payload.type === 'city_from') {
        state.pierDropdownCityFrom = action.payload.data.piers
      } else {
        state.pierDropdownCityTo = action.payload.data.piers
      }
    },
  },
})