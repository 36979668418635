import React, {useState} from 'react'
import {Card, Col, Row, Table} from 'react-bootstrap'
import Select from 'react-select'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {Input} from '../../../../../_metronic/partials/controls'
import RenderSmoothImage from '../../../hotels/pages/components/steps/RenderSmoothImage'
import DummySightSeeing from '../../../../../_metronic/assets/hotel-colour.jpg'
import moment from 'moment'
import {DateFormat} from '../../../../services/Functional'
import {PrefixOption} from '../hotel_checkout_details/HotelCheckOutDetails'

interface props {
    items: any
    handleChange: any
    handleBlur: any
    index: number
}

const PackageCheckOutDetails = ({items, index, handleChange}: props) => {
    const [showPackageDetails, setShowPackageDetails] = useState<any[]>([0])

    const {values}: any = useFormikContext()
    const showPackage = (index: number) => {
        let tempData = [...showPackageDetails]
        if (tempData.indexOf(index) !== -1) {
            tempData.splice(tempData.indexOf(index), 1)
            setShowPackageDetails(tempData)
        } else {
            tempData.push(index)
            setShowPackageDetails(tempData)
        }
    }

    return (
        <React.Fragment>
            <Card className={'gutter-b'}>
                <Card.Body className={'p-2 '}>
                    <div
                        role='button'
                        className={`${
                            showPackageDetails.includes(index) ? 'border-bottom' : ''
                        } py-2 px-3 d-flex justify-content-between align-items-center`}
                        onClick={() => showPackage(index)}
                    >
                        <div className={'fs-4 fw-semibold fs-3 d-flex align-items-center'}>
                            <i className='fa-solid fa-briefcase me-2 fs-2'/>
                            <span className='me-1'>{items?.package_response?.name}</span>
                            (
                            <div className={'fw-semibold text-primary fs-6'}>
                                Total Price(USD):
                                <span
                                    className={'text-muted ms-1'}>{items?.price}</span>
                            </div>
                            )
                        </div>
                        <div>
                            {showPackageDetails.includes(index) ? (
                                <i className='las la-chevron-circle-up fs-2x'/>
                            ) : (
                                <i className='las la-chevron-circle-down fs-2x'/>
                            )}
                        </div>
                    </div>
                    {showPackageDetails.includes(index) && (
                        <Row>
                            <Col md={4}>
                                <div className={'spinnerCenter position-relative py-2'}>
                                    <div className={'d-flex gap-2 border-bottom mb-2 pb-1'}>
                                        <RenderSmoothImage
                                            src={items?.package_response?.logo ? (process.env.REACT_APP_AWS_URL + items?.package_response?.logo) : DummySightSeeing}
                                            extraClassName={'img-fluid rounded h-75px'}
                                        />
                                        <div className={' fs-4 fw-semibold pb-1'}>
                                            {items?.package_response.name}
                                            <div className={'fw-semibold text-primary fs-6 mb-1 '}>
                                                Total Price(USD):
                                                <span
                                                    className={'text-muted ms-1'}>{items?.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={'text-center my-2 fw-semibold bg-gray-200i py-1 fs-5 text-gray-700'}
                                    >
                                        Package Information
                                    </div>

                                    <div className={'d-flex gap-1 mb-3 text-primary ps-4 fw-semibold fs-6'}>
                                        <span>Pickup Date:</span><DateFormat items={items?.searchData?.pickup_date}/>
                                    </div>
                                    <div className={'d-flex gap-1 mb-3 text-primary ps-4 fw-semibold fs-6'}>
                                        <span>Type:</span><span
                                        className='text-muted'>{items?.package_response?.package_type}</span>
                                    </div>
                                    <div className={'d-flex gap-1 mb-3 text-primary ps-4 fw-semibold fs-6'}>
                                        <span>Code:</span><span
                                        className='text-muted'>{items?.package_response?.package_code}</span>
                                    </div>
                                    <div className={'fw-semibold fs-6 ps-4'}>
                                        <div className={'text-muted mb-3'}>
                                            {items?.searchData?.adult} ADULTS
                                            {items?.searchData?.child_count !== 0 &&
                                                <React.Fragment>
                                                    {' '}|{' '}
                                                    {items?.searchData?.child_count}{' '}
                                                    CHILD({items?.searchData?.child.map((child: any, child_index: number) => (
                                                    <span
                                                        key={`child_age_${child_index}`}>{child.age}, </span>
                                                ))}) Yrs
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div className={'fw-semibold text-gray-800 fs-6 pb-1'}>
                                            <div className={'text-danger d-flex'}>
                                                Cancel Up To:{' '} <span className={'text-muted ms-1'}><DateFormat
                                                items={items?.package_response?.cancel_upto}/></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={8} className={'border-start pt-2'}>
                                <Row className={'mt-2'}>
                                    <Col md={12}>
                                        <div className='text-danger mt-1 fw-semibold my-2 text-center text-uppercase'>
                                            Hotel Details
                                        </div>
                                    </Col>
                                </Row>
                                {(values?.packages && values?.packages[index]?.hotels.length !== 0) &&
                                    <React.Fragment>
                                        <Row>
                                            <Table bordered={true}
                                                   className={'border-bottom text-center custom-table-border rounded-2'}>
                                                <thead className={'bg-light'}>
                                                <tr>
                                                    <th>City</th>
                                                    <th>Hotel</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {values?.packages[index]?.hotels.map((hotel: {
                                                    city_name: string,
                                                    hotel_name: string
                                                }, hotelIndex: number) => (
                                                    <tr key={`hotels_details${hotelIndex + 1}`}
                                                        className={'vertical-align-middle'}>
                                                        <td>{hotel.city_name}</td>
                                                        <td>{hotel.hotel_name}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </React.Fragment>
                                }
                                <Row className={'mt-2'}>
                                    <Col md={12}>
                                        <div className='text-danger mt-1 fw-semibold my-2 text-center text-uppercase'>
                                            Itineraries Details
                                        </div>
                                    </Col>
                                </Row>
                                {values?.packages && values?.packages[index] && values?.packages[index]?.itineraries.length !== 0 && values?.packages[index]?.itineraries.map((_: any, itineraryIndex: number) => (
                                    <React.Fragment key={`itinerary_details${itineraryIndex + 1}`}>
                                        <Row>
                                            <Col md={12}>
                                                <p className='fs-5'>Day {values?.packages[index]?.itineraries[itineraryIndex].day}
                                                    ({moment(values?.packages[index]?.itineraries[itineraryIndex].date).format('DD-MM-YYYY')})
                                                    - {values?.packages[index]?.itineraries[itineraryIndex].service}
                                                </p>
                                            </Col>
                                            {(values?.packages[index]?.itineraries[itineraryIndex].service_type === 'Arrival' || values?.packages[index]?.itineraries[itineraryIndex].service_type === 'Departure')
                                                ? <>
                                                    <Col md={3}>
                                                        <div className={'form-group'}>
                                                            <label
                                                                className='form-label'>{values?.packages[index]?.itineraries[itineraryIndex].service_type} Flight
                                                                No.</label>
                                                            <Field
                                                                name={`packages[${index}].itineraries[${itineraryIndex}].flight_no`}
                                                                component={Input}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className={'form-group'}>
                                                            <label
                                                                className='form-label'>ET{values?.packages[index]?.itineraries[itineraryIndex].service_type.charAt(0)}</label>
                                                            <Field
                                                                name={`packages[${index}].itineraries[${itineraryIndex}].estimated_time`}
                                                                type='time'
                                                                component={Input}
                                                            />
                                                        </div>
                                                    </Col>
                                                </>
                                                : null}
                                            {values?.packages[index]?.itineraries[itineraryIndex].type !== 'Leisure' && <>
                                                <Col md={3}>
                                                    <div className={'form-group'}>
                                                        <label className='form-label'>Pickup Time</label>
                                                        <Field
                                                            name={`packages[${index}].itineraries[${itineraryIndex}].pickup_time`}
                                                            type='time'
                                                            component={Input}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className={'form-group'}>
                                                        <label className='form-label'>Service Time</label>
                                                        <p>{values.packages[index].itineraries[itineraryIndex].time}</p>
                                                    </div>
                                                </Col>
                                            </>}
                                        </Row>
                                    </React.Fragment>
                                ))}
                                <Row className={'mt-2'}>
                                    <Col md={12}>
                                        <div className='text-danger mt-1 fw-semibold my-2 text-center'>
                                            PASSENGER DETAILS
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        {values?.packages && values?.packages[index]?.adults.length !== 0 && Array.from({length: values?.packages[index]?.adults.length}, (_: any, passIndex: number) => (
                                            <React.Fragment key={`OTHER_PASSENGER_DETAILS${passIndex}`}>
                                                <Row className='px-2'>
                                                    <Col md={2}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>Prefix</label>
                                                            <Select
                                                                options={PrefixOption}
                                                                value={PrefixOption.find((prefix) => prefix.value === values?.packages[index]?.adults[passIndex]?.prefix)}
                                                                name={`packages[${index}].adults[${passIndex}].prefix`}
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                placeholder='Select a prefix'
                                                                onChange={(option: any) => {
                                                                    let event = {
                                                                        target: {
                                                                            name: `packages[${index}].adults[${passIndex}].prefix`,
                                                                            value: option.value,
                                                                        },
                                                                    }
                                                                    handleChange(event)
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name={`packages[${index}].adults[${passIndex}].prefix`}
                                                                component='div' className='text-danger mt-2'/>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>First Name</label>
                                                            <Field
                                                                name={`packages[${index}].adults[${passIndex}].first_name`}
                                                                component={Input}
                                                                placeholder={'first name'}
                                                            />
                                                            <ErrorMessage
                                                                name={`packages[${index}].adults[${passIndex}].first_name`}
                                                                component='div' className='text-danger mt-2'/>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>Last Name</label>
                                                            <Field
                                                                name={`packages[${index}].adults[${passIndex}].last_name`}
                                                                component={Input}
                                                                placeholder={'last name'}
                                                            />
                                                            <ErrorMessage
                                                                name={`packages[${index}].adults[${passIndex}].last_name`}
                                                                component='div' className='text-danger mt-2'/>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>PAN Number</label>
                                                            <Field
                                                                name={`packages[${index}].adults[${passIndex}].pan_number`}
                                                                component={Input}
                                                                placeholder={'Pan number'}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ))}
                                    </Col>
                                    <Col md={12}>
                                        {values?.packages && values?.packages[index]?.children.length !== 0 && Array.from({length: values?.packages[index]?.children.length}, (_: any, passIndex: number) => (
                                            <React.Fragment key={`CHILD_PASSENGER_DETAILS_${passIndex}`}>
                                                <Row
                                                    className='px-2'
                                                >
                                                    <Col md={2}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>Prefix</label>
                                                            <Select
                                                                options={PrefixOption}
                                                                value={PrefixOption.find((prefix) => prefix.value === values?.packages[index]?.children[passIndex]?.prefix)}
                                                                name={`packages[${index}].children[${passIndex}].prefix`}
                                                                className='react-select-container'
                                                                classNamePrefix='react-select'
                                                                placeholder='Select a prefix'
                                                                onChange={(option: any) => {
                                                                    let event = {
                                                                        target: {
                                                                            name: `packages[${index}].children[${passIndex}].prefix`,
                                                                            value: option.value,
                                                                        },
                                                                    }
                                                                    handleChange(event)
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name={`packages[${index}].children[${passIndex}].prefix`}
                                                                component='div' className='text-danger mt-2'/>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>First Name</label>
                                                            <Field
                                                                name={`packages[${index}].children[${passIndex}].first_name`}
                                                                component={Input}
                                                                placeholder={'first name'}
                                                            />
                                                            <ErrorMessage
                                                                name={`packages[${index}].children[${passIndex}].first_name`}
                                                                component='div' className='text-danger mt-2'/>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>Last Name</label>
                                                            <Field
                                                                name={`packages[${index}].children[${passIndex}].last_name`}
                                                                component={Input}
                                                                placeholder={'last name'}
                                                            />
                                                            <ErrorMessage
                                                                name={`packages[${index}].children[${passIndex}].last_name`}
                                                                component='div' className='text-danger mt-2'/>
                                                        </div>
                                                    </Col>
                                                    <Col md={4}>
                                                        <div className={'form-group'}>
                                                            <label className='form-label'>Age</label>
                                                            <Field
                                                                name={`packages[${index}].children[${passIndex}].age`}
                                                                component={Input}
                                                                placeholder={'age'}
                                                            />
                                                            <ErrorMessage
                                                                name={`packages[${index}].children[${passIndex}].age`}
                                                                component='div' className='text-danger mt-2'/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        ))}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className='mt-4 px-5'>
                                        <div className={'form-group'}>
                                            <label className='form-label text-danger'>Special Request</label>
                                            <Field name={`packages[${index}].special_request`} as='textarea'
                                                   className='form-control'
                                                   rows={2}/>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}

export {PackageCheckOutDetails}
