import {createSlice} from '@reduxjs/toolkit'

const initialConfigurationState = {
  configurations: null,
  currencies: [],
}

export const ConfigurationSlices = createSlice({
  name: 'sites',
  initialState: initialConfigurationState,
  reducers: {
    configurations: (state, action) => {
      if (action.payload.configurations) {
        let configuration: any = {}
        if (action.payload.configurations && action.payload.configurations.length) {
          action.payload.configurations.map((data: any) => {
            configuration[data.key_param] = data.value
          })
          state.configurations = configuration
        }
      }
    },
    cleanConfiguration: (state, action) => {
      state.configurations = null
    },
    currencies:
      (state, action) => {
        state.currencies = action.payload.currencies
      },
  },
})

export const {cleanConfiguration} = ConfigurationSlices.actions