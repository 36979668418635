export function manageLeadPassengerDetails(field: string, fieldValue: any, handleChange: any, values: any) {
    if (values.hotels && values.hotels.length > 0) {
        values?.hotels?.map((hotel: any, index: number) => {
            handleChange({
                target: {
                    name: `hotels[${index}].rooms[0].adults[0].${field}`,
                    value: fieldValue,
                }
            })
        })
    }if (values.sight_seeings && values.sight_seeings.length > 0) {
        values?.sight_seeings?.map((hotel: any, index: number) => {
            handleChange({
                target: {
                    name: `sight_seeings[${index}].adults[0].${field}`,
                    value: fieldValue,
                }
            })
        })
    }
    if (values.transfers && values.transfers.length > 0) {
        values?.transfers?.map((hotel: any, index: number) => {
            handleChange({
                target: {
                    name: `transfers[${index}].adults[0].${field}`,
                    value: fieldValue,
                }
            })
        })
    }
    if (values.packages && values.packages.length > 0) {
        values?.packages?.map((hotel: any, index: number) => {
            handleChange({
                target: {
                    name: `packages[${index}].adults[0].${field}`,
                    value: fieldValue,
                }
            })
        })
    }
}