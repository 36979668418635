import React, {useMemo} from 'react'
import {Form, Formik} from 'formik'
import {Card, Col, Row} from 'react-bootstrap'
import RenderSmoothImage from '../../hotels/pages/components/steps/RenderSmoothImage'
import DummyHotel from '../../../../_metronic/assets/hotel-colour.jpg'
import moment from 'moment'
import {
    addToCartHotels,
    addToCartPackages,
    addToCartSightSeeing,
    addToCartTransfers,
} from '../../../../setup/redux/bookings/slices'
import {useDispatch} from 'react-redux'
import {checkEmpty, getRoomInclusion} from '../../../services/Functional'
import {TotalCheckOutPrice} from './total_price/TotalCheckOutPrice'
import Swal from "sweetalert2";

const initialValues = {}

function DateFormat(props: { items: any }) {
    return <>{moment(props.items).format('DD/MM/YYYY')}</>
}

export const CustomForm = ({cartItem}: any) => {
    const dispatch = useDispatch()

    const {hotels, sightSeeings, packages, transfers} = cartItem

    const RemoveHotelItem = (index: number) => {
        let dataArray = [...hotels]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartHotels(dataArray))
        }
    }

    const RemoveSightSeeingItem = (index: number) => {
        let dataArray = [...sightSeeings]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartSightSeeing(dataArray))
        }
    }
    const RemovePackageItem = (index: number) => {
        let dataArray = [...packages]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartPackages(dataArray))
        }
    }
    const RemoveTransferItem = (index: number) => {
        let dataArray = [...transfers]
        if (index >= 0 && index < dataArray.length) {
            dataArray.splice(index, 1)
            dispatch(addToCartTransfers(dataArray))
        }
    }

    const totalCartItem = useMemo(
        () =>
            cartItem.hotels.length +
            cartItem.sightSeeings.length +
            cartItem.transfers.length +
            cartItem.packages.length,
        [cartItem],
    )
    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    console.log('values', values)
                }}
                enableReinitialize={true}
            >
                {({values, handleSubmit}) => (
                    <React.Fragment>
                        <Form>
                            <Row className={'m-3 mt-0'}>
                                <Col md={12}>
                                    <div className={'text-gray-800 fs-5 fw-semibold mb-2'}>
                                        {totalCartItem || 0} Item(s) are in Cart
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'m-3 mt-0'}>
                                <Col md={12} className={'px-0'}>
                                    {hotels && hotels.length !== 0 && (
                                        <div className={'text-gray-800 fs-4 fw-semibold'}>Hotels:</div>
                                    )}
                                </Col>
                            </Row>
                            <Row className={'mx-0'}>
                                <Col md={10}>
                                    <Row>
                                        <Col md={12}>
                                            {hotels &&
                                                hotels.length !== 0 &&
                                                hotels.map((items: any, index: number) => (
                                                    <React.Fragment key={`hotels_loop${index}`}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Card className='gutter-b'>
                                                                    <Card.Body className={'p-4'}>
                                                                        <Row>
                                                                            <Col md={2}
                                                                                 className={'spinnerCenter position-relative '}>
                                                                                <RenderSmoothImage
                                                                                    src={items?.hotel?.logo ? (process.env.REACT_APP_AWS_URL + items?.hotel?.logo) : DummyHotel}
                                                                                    extraClassName={'img-fluid rounded h-100px'}
                                                                                />
                                                                            </Col>
                                                                            <Col md={9}>
                                                                                <div
                                                                                    className={' fs-4 fw-semibold pb-1'}>
                                                                                    {items?.hotel?.name}
                                                                                </div>
                                                                                {items?.room_info && items?.room_info.length !== 0 ?
                                                                                    items?.room_info.map((room: any, roomIndex: number) => (
                                                                                        <React.Fragment>
                                                                                            <Row>
                                                                                                <Col md={9}>
                                                                                                    <div
                                                                                                        className={'fw-semibold text-gray-800 fs-5 pb-1'}>
                                                                                                        Room
                                                                                                        Type: {room?.roomData?.name} ({getRoomInclusion(room.roomData.room_inclusion)})
                                                                                                    </div>

                                                                                                    <div
                                                                                                        className={'fw-semibold text-muted fs-6 pb-1'}>
                                                                                                        <DateFormat
                                                                                                            items={items?.booking_info?.check_in}
                                                                                                        />{' '}
                                                                                                        -{' '}
                                                                                                        <DateFormat
                                                                                                            items={items?.booking_info?.check_out}
                                                                                                        />{' '}
                                                                                                        -
                                                                                                        ({items?.booking_info?.total_night} Nights)
                                                                                                        | 1
                                                                                                        ROOM
                                                                                                        | {room?.searchData?.adult} ADULTS
                                                                                                        {room?.searchData?.child_count !== 0 &&
                                                                                                            <React.Fragment>
                                                                                                                {' '}|{' '}
                                                                                                                {room?.searchData?.child_count}{' '}
                                                                                                                CHILD({room?.searchData?.child.map((child: any, child_index: number) => (
                                                                                                                <span
                                                                                                                    key={`child_age_${child_index}`}>{child.age} {child?.child_bed_type === true ? 'CWB' : 'CNB'} </span>
                                                                                                            ))}) Yrs
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={'fs-6'}>
                                                                                                        <span
                                                                                                            className='text-danger'> Cancel Upto:</span>
                                                                                                        <DateFormat
                                                                                                            items={room?.roomData?.cancel_upto}
                                                                                                        />
                                                                                                        {!room?.roomData?.availability && <span className="text-danger"> (On Request)</span>}
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md={3}
                                                                                                     className={'d-flex align-items-center'}>
                                                                                                    <div
                                                                                                        className={'text-muted fs-5'}>
                                                                                                        USD: <span
                                                                                                        className='text-danger'>{room?.roomData?.price}/-</span>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </React.Fragment>
                                                                                    ))
                                                                                    : null}
                                                                            </Col>
                                                                            <Col
                                                                                md={1}
                                                                                className={
                                                                                    'd-flex align-items-center justify-content-center'
                                                                                }
                                                                            >
                                                                                <i
                                                                                    className='las la-trash-alt fs-1 text-danger'
                                                                                    role={'button'}
                                                                                    onClick={() => Swal.fire({
                                                                                        icon: 'warning',
                                                                                        title: 'Are you sure?',
                                                                                        text: 'You won\'t be able to revert this!',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Yes',
                                                                                        cancelButtonText: 'No',
                                                                                        reverseButtons: true,
                                                                                        allowOutsideClick: false,
                                                                                    }).then(async (result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            RemoveHotelItem(index)
                                                                                        }
                                                                                    })}
                                                                                />
                                                                            </Col>

                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                        </Col>
                                    </Row>
                                    <Row className={'m-3 mt-0'}>
                                        <Col md={12} className={'px-0'}>
                                            {sightSeeings && sightSeeings.length !== 0 && (
                                                <div className={'text-gray-800 fs-4 fw-semibold'}>Sight Seeings:</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {sightSeeings &&
                                                sightSeeings.length !== 0 &&
                                                sightSeeings.map((items: any, index: number) => (
                                                    <React.Fragment key={`sightSeeingLoop${index}`}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Card className='gutter-b'>
                                                                    <Card.Body className={'p-4'}>
                                                                        <Row>
                                                                            <Col md={2}
                                                                                 className={'spinnerCenter position-relative '}>
                                                                                <RenderSmoothImage
                                                                                    src={
                                                                                        items?.sight_seeing?.logo
                                                                                            ? process.env.REACT_APP_AWS_URL + items?.sight_seeing?.logo
                                                                                            : DummyHotel
                                                                                    }
                                                                                    extraClassName={'img-fluid rounded h-100px w-175px'}
                                                                                />
                                                                            </Col>
                                                                            <Col md={7}>
                                                                                <div
                                                                                    className={' fs-4 fw-semibold pb-1'}>
                                                                                    {items?.sight_seeing?.name}
                                                                                </div>
                                                                                <div
                                                                                    className={'fw-semibold text-muted fs-5 pb-1'}>
                                                                                    <span> Category:-{checkEmpty(items?.category)}</span>,
                                                                                    <span> Ticket Type:-{items?.type}</span>
                                                                                </div>
                                                                                <div
                                                                                    className={'fw-semibold text-muted fs-6 pb-1'}>
                                                                                    <DateFormat
                                                                                        items={items?.searchData?.pickup_date}
                                                                                    />{' '}
                                                                                    |{' '}
                                                                                    {checkEmpty(
                                                                                        moment(items?.pickup_time, '"HH:mm:ss"').format('HH:mm A'),
                                                                                    )}
                                                                                    |{' '}
                                                                                    {items?.searchData?.adult} ADULTS
                                                                                    |{' '}
                                                                                    {items?.searchData?.child_count} CHILD
                                                                                </div>
                                                                                <div className={'fs-6'}>
                                                                                    <span className='text-danger'> Cancel Upto:</span>
                                                                                    <DateFormat
                                                                                        items={items?.sight_seeing?.cancel_upto}
                                                                                    />
                                                                                    {!items.sight_seeing.availability && <span className="text-danger"> (On Request)</span>}
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                md={2}
                                                                                className={
                                                                                    'd-flex align-items-center justify-content-center'
                                                                                }
                                                                            >
                                                                                <div className={'text-danger fs-4'}>
                                                                                    USD:-{items?.price}/-
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                md={1}
                                                                                className={
                                                                                    'd-flex align-items-center justify-content-center'
                                                                                }
                                                                            >
                                                                                <i
                                                                                    className='las la-trash-alt fs-1 text-danger'
                                                                                    role={'button'}
                                                                                    onClick={() => Swal.fire({
                                                                                        icon: 'warning',
                                                                                        title: 'Are you sure?',
                                                                                        text: 'You won\'t be able to revert this!',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Yes',
                                                                                        cancelButtonText: 'No',
                                                                                        reverseButtons: true,
                                                                                        allowOutsideClick: false,
                                                                                    }).then(async (result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            RemoveSightSeeingItem(index)
                                                                                        }
                                                                                    })}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                        </Col>
                                    </Row>
                                    <Row className={'m-3 mt-0'}>
                                        <Col md={12} className={'px-0'}>
                                            {transfers && transfers.length !== 0 && (
                                                <div className={'text-gray-800 fs-4 fw-semibold'}>Transfers:</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {transfers &&
                                                transfers.length !== 0 &&
                                                transfers.map((items: any, index: number) => (
                                                    <React.Fragment key={`packageLoop${index + 1}`}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Card className='gutter-b'>
                                                                    <Card.Body className={'p-4'}>
                                                                        <Row>
                                                                            <Col md={2}
                                                                                 className={'spinnerCenter position-relative '}>
                                                                                <RenderSmoothImage
                                                                                    src={
                                                                                        items?.transfer_response?.logo
                                                                                            ? process.env.REACT_APP_AWS_URL + items?.transfer_response?.logo
                                                                                            : DummyHotel
                                                                                    }
                                                                                    extraClassName={'img-fluid rounded h-100px w-175px'}
                                                                                />
                                                                            </Col>
                                                                            <Col md={7}>
                                                                                <div
                                                                                    className={' fs-4 fw-semibold pb-1'}>
                                                                                    {items?.transfer_response?.name}
                                                                                </div>
                                                                                <div
                                                                                    className={'fw-semibold text-muted fs-5 pb-1'}>
                                                                                    <span> Transfer Type:-{checkEmpty(items?.transfer_response?.transfer_type)}</span>,
                                                                                    <span
                                                                                        className='text-capitalize'> Type:-{items?.transfer_type}</span>,
                                                                                    <span> Max Baggage:-{items?.baggage_allow}</span>
                                                                                </div>
                                                                                <div
                                                                                    className={'fw-semibold text-muted fs-6 pb-1'}>
                                                                                    <DateFormat
                                                                                        items={items?.searchData?.transfer_date}
                                                                                    />{' '}
                                                                                    |{' '}
                                                                                    {items?.searchData?.adult} ADULTS
                                                                                    |{' '}
                                                                                    {items?.searchData?.child_count} CHILD
                                                                                </div>
                                                                                <div className={'fs-6'}>
                                                                                    <span className='text-danger'> Cancel Upto:</span>
                                                                                    <DateFormat
                                                                                        items={items?.transfer_response?.cancel_upto}
                                                                                    />
                                                                                    {!items.transfer_response.availability && <span className="text-danger"> (On Request)</span>}
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={2}
                                                                                 className={'d-flex align-items-center'}>
                                                                                <div className={'text-muted fs-5'}>
                                                                                    USD: <span
                                                                                    className='text-danger'>{items?.price}/-</span>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                md={1}
                                                                                className={
                                                                                    'd-flex align-items-center justify-content-center'
                                                                                }
                                                                            >
                                                                                <i
                                                                                    className='las la-trash-alt fs-1 text-danger'
                                                                                    role={'button'}
                                                                                    onClick={() => Swal.fire({
                                                                                        icon: 'warning',
                                                                                        title: 'Are you sure?',
                                                                                        text: 'You won\'t be able to revert this!',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Yes',
                                                                                        cancelButtonText: 'No',
                                                                                        reverseButtons: true,
                                                                                        allowOutsideClick: false,
                                                                                    }).then(async (result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            RemoveTransferItem(index)
                                                                                        }
                                                                                    })}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                        </Col>
                                    </Row>
                                    <Row className={'m-3 mt-0'}>
                                        <Col md={12} className={'px-0'}>
                                            {packages && packages.length !== 0 && (
                                                <div className={'text-gray-800 fs-4 fw-semibold'}>Packages:</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            {packages &&
                                                packages.length !== 0 &&
                                                packages.map((items: any, index: number) => (
                                                    <React.Fragment key={`packageLoop${index + 1}`}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Card className='gutter-b'>
                                                                    <Card.Body className={'p-4'}>
                                                                        <Row>
                                                                            <Col md={2}
                                                                                 className={'spinnerCenter position-relative '}>
                                                                                <RenderSmoothImage
                                                                                    src={
                                                                                        items?.package_response?.logo
                                                                                            ? process.env.REACT_APP_AWS_URL + items?.package_response?.logo
                                                                                            : DummyHotel
                                                                                    }
                                                                                    extraClassName={'img-fluid rounded h-100px w-175px'}
                                                                                />
                                                                            </Col>
                                                                            <Col md={7}>
                                                                                <div
                                                                                    className={' fs-4 fw-semibold pb-1'}>
                                                                                    {items?.package_response?.name}
                                                                                </div>
                                                                                <div
                                                                                    className={'fw-semibold text-muted fs-5 pb-1'}>
                                                                                    <span> Code:-{checkEmpty(items?.package_response?.package_code)}</span>,
                                                                                    <span> Type:-{items?.package_response?.package_type}</span>
                                                                                </div>
                                                                                <div
                                                                                    className={'fw-semibold text-muted fs-6 pb-1'}>
                                                                                    <DateFormat
                                                                                        items={items?.searchData?.pickup_date}
                                                                                    />{' '}
                                                                                    |{' '}
                                                                                    {items?.searchData?.adult} ADULTS
                                                                                    |{' '}
                                                                                    {items?.searchData?.child_count} CHILD
                                                                                </div>
                                                                                <div className={'fs-6'}>
                                                                                    <span className='text-danger'> Cancel Upto:</span>
                                                                                    <DateFormat
                                                                                        items={items?.package_response?.cancel_upto}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col md={2}
                                                                                 className={'d-flex align-items-center'}>
                                                                                <div className={'text-muted fs-5'}>
                                                                                    USD: <span
                                                                                    className='text-danger'>{items?.price}/-</span>
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                md={1}
                                                                                className={
                                                                                    'd-flex align-items-center justify-content-center'
                                                                                }
                                                                            >
                                                                                <i
                                                                                    className='las la-trash-alt fs-1 text-danger'
                                                                                    role={'button'}
                                                                                    onClick={() => Swal.fire({
                                                                                        icon: 'warning',
                                                                                        title: 'Are you sure?',
                                                                                        text: 'You won\'t be able to revert this!',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonText: 'Yes',
                                                                                        cancelButtonText: 'No',
                                                                                        reverseButtons: true,
                                                                                        allowOutsideClick: false,
                                                                                    }).then(async (result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            RemovePackageItem(index)
                                                                                        }
                                                                                    })}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                ))}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={2}>
                                    <TotalCheckOutPrice/>
                                </Col>
                            </Row>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </React.Fragment>
    )
}
