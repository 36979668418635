import React from "react";
import "../../_metronic/assets/sass/components/_loader.scss";
import {toAbsoluteUrl} from '../../_metronic/helpers'

export function Loader({message = ''}) {
    return (
        <>
            <div className="loader bg-body opacity-50">
                <div className="loader_inner">
                    <img
                        src={toAbsoluteUrl("/media/spinner.gif")}
                        alt="Loading"
                    />

                    {(() => {
                        if (message !== '') {
                            return (<p>{message}</p>)
                        }
                    })()}
                </div>
            </div>
        </>
    );
}
