import {createSlice} from '@reduxjs/toolkit'

const initialHotelState = {
  totalCount: 0,
  hotels: null,
  hotel: undefined,
  hotelShow: undefined,
  hotelRooms: [
    {
      id: undefined,
      name: '',
      pictures: [],
      room_amenities: [],
    },
  ],
  markup: undefined,
  roomDetails: undefined,
  roomInfo: undefined,
  hotelPictures: undefined,
  hotelBasicInfo: null,
  roomMarkupInfo: null,
  stopSale: {},
  allotment: {},
  dailyPrice: [],
  hotelByCityId: [],
  roomsByHotelId: [],
  bookingSearchHotel: [],
  bookingSearchHotelCount: 0
}

export const HotelSlices = createSlice({
  name: 'hotels',
  initialState: initialHotelState,
  reducers: {
    hotel: (state, action) => {
      state.hotel = action.payload.hotel
    },
    resetHotel: (state, action) => {
      state.hotel = undefined
    },
    hotelShow: (state, action) => {
      state.hotelShow = action.payload.hotel
    },

    hotelRooms: (state, action) => {
      state.hotelRooms = action.payload
    },

    hotels: (state, action) => {
      state.hotels = action.payload.hotels
      state.totalCount = action.payload.total_count
    },

    hotelPictures: (state, action) => {
      state.hotelPictures = action.payload.pictures
    },
    resetHotelPictures: (state, action) => {
      state.hotelPictures = action.payload
    },

    hotelBasicInfo: (state, action) => {
      state.hotelBasicInfo = action.payload.hotel_basic_info
    },

    roomMarkupInfo: (state, action) => {
      state.roomMarkupInfo = action.payload.data
    },
    resetRoomMarkupInfo: (state, action) => {
      state.roomMarkupInfo = null
    },

    roomMarkup: (state, action) => {
      state.markup = action.payload.data.markup
      state.roomDetails = action.payload.data.room_details
    },
    stopSale: (state, action) => {
      state.stopSale = action.payload.data
    },
    allotment: (state, action) => {
      state.allotment = action.payload.data
    },
    dailyPrice: (state, action) => {
      state.dailyPrice = action.payload.data
    },
    hotelByCityId: (state, action) => {
      state.hotelByCityId = action.payload.data.hotels
    },
    roomsByHotelId: (state, action) => {
      state.roomsByHotelId = action.payload.data.rooms
    },
    hotelsByBookingSearch: (state, action) => {
      state.bookingSearchHotel = action.payload.data.hotels
      state.bookingSearchHotelCount = action.payload.data.total_count
    },
    roomInfo: (state, action) => {
      state.roomInfo = action.payload.room_details
    },
  },
})

export const {resetHotelPictures, resetRoomMarkupInfo, resetHotel} = HotelSlices.actions