import React, {useEffect, useState} from 'react'
import {Loader} from '../../../services/Loader'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {AuthFooter} from '../../../services/Functional'
import OtpInput from 'react-otp-input'
import {getUserAccess, verifyOTP} from '../../../../setup/redux/auth/actions'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

export function Otp() {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {config, user, access_token}: any = useSelector<RootState>(
    (state:RootState) => ({
      config: state.configuration.configurations,
      user: state.auth.user,
      access_token: state.auth.access_token,
    }),
    shallowEqual,
  )
  const [otp, setOTP] = React.useState('')

  const handleOTPChange = async (otp: string) => {
    setOTP(otp)
    if (otp.length === 6) {
      setLoading(true)
      const response: any = await dispatch<any>(verifyOTP({id: user?.id, otp}))
      if (response?.success) {
        setHasErrors(false)
        setMessage('')
        await dispatch<any>(getUserAccess())
      } else {
        setHasErrors(true)
        setMessage(response?.message)
        setOTP('')
      }
      setLoading(false)
    }
  }
  useEffect(() => {
    if (access_token) {
      navigate('/dashboard')
    }
  }, [user])
  return (
    <>
      {loading && <Loader />}
      <div className="row w-100 h-100 p-0 m-0">
        <div className="col-md-6 background_card">
          <img src={toAbsoluteUrl("/media/actual_logo.png")} alt="" />
        </div>
        <div className="col-md-6 p-0 bg-body">
          <div className="login_card">
            <div className="login_form">
              <div className="mb-15 animate__animated animate__fadeInUp">
                <h2 className="font-weight-bold">Enter OTP</h2>
              </div>
              {hasErrors && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {message}
                  </div>
                </div>
              )}
              <OtpInput
                shouldAutoFocus={true}
                isInputNum={true}
                isInputSecure={true}
                inputStyle={{
                  borderRadius: '12px',
                  boxShadow: '4px 4px 6px 0 #eff0f6, inset 4px 4px 6px 0 #eaebf4',
                  backgroundColor: '#ffffff',
                  width: '60px',
                  height: '60px',
                  padding: '15px',
                  fontSize: '25.2px',
                  fontWeight: '500',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '1.38',
                  letterSpacing: 'normal',
                  textAlign: 'center',
                  color: '#262626',
                  // opacity: '0.66',
                  border: 'solid 1px #f4f4f4',
                }}
                value={otp}
                className="pin_input"
                onChange={async (otp: any) => {
                  handleOTPChange(otp)
                }}
                numInputs={6}
                separator={<span style={{margin: '0 0.5rem'}}>-</span>}
              />
              <AuthFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}