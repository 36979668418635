import React, {useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {useSelector} from 'react-redux'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {postRequest} from '../../../setup/redux/common/cruds'
import {RootState} from '../../../setup'
import {PageTitle} from '../../../_metronic/layout/core'
import {Loader} from '../../services/Loader'
import {Input} from '../../../_metronic/partials/controls'

const initRequest = {
  password: '',
  confirm_password: '',
  old_password: '',
  username: '',
}
const ProfileSchema = Yup.object().shape({
  old_password: Yup.string().required('Old password is required'),
  username: Yup.string().required('Username is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password does not match')
    .required('Confirm password is required'),
})


export const UpdatePassword = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)

  const saveRequest = (values: any) => {
    setLoading(true)
    postRequest(`${process.env.REACT_APP_API_BASE_URL}auth/change-password`, values).then(response => {
      setLoading(false)
      Swal.fire({
        title: response.data.success ? 'Success' : 'Warning',
        html: response.data.message,
        icon: response.data.success ? 'success' : 'warning',
        allowOutsideClick: false,
      }).then(() => {
        Swal.close()
        navigate('/dashboard')
      }).catch(e => {
        setLoading(false)
      })
    })
  }
// @ts-ignore
  const {user} = useSelector<RootState>(state => state.auth)
  let userDetail = {
    password: '',
    confirm_password: '',
    old_password: '',
    username: user.username,
  }

  return (
    <>
      {loading && <Loader />}
      <PageTitle>Change Password</PageTitle>
      <div id='kt_content_container' className='container'>

        <Formik
          enableReinitialize={true}
          initialValues={userDetail || initRequest}
          validationSchema={ProfileSchema}
          onSubmit={(values) => {
            saveRequest(values)
          }}
        >
          {({values, handleSubmit, handleChange, errors, touched, handleBlur}) => (
            <>
              <Form className='form' autoComplete="false">
                <div className='card gutter-b'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className="form-label">
                            Username <span className="text-danger">*</span>
                          </label>
                          <Field name='username' component={Input} placeholder='Ex. john_doe'
                                 className='form-control' disabled={true} />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>Old Password <span
                            className="text-danger">*</span></label>
                          <Field name='old_password' type='password' component={Input} placeholder='******'
                                 className='form-control' />
                          <ErrorMessage name='old_password' component="div" className="text-danger mt-2" />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>Password <span
                            className="text-danger">*</span></label>
                          <Field name='password' type='password' component={Input} placeholder='******'
                                 className='form-control' />
                          <ErrorMessage name='password' component="div" className="text-danger mt-2" />
                        </div>
                      </div>
                      <div className='col-md-4'>
                        <div className='form-group'>
                          <label className='form-label'>Confirm Password <span
                            className="text-danger">*</span></label>
                          <Field name='confirm_password' type='password' component={Input} placeholder='******'
                                 className='form-control' />
                          <ErrorMessage name='confirm_password' component="div" className="text-danger mt-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card gutter-b'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <button type='submit' onSubmit={() => handleSubmit} className='btn btn-primary'>Update</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  )
}