import * as requestFromServer from '../common/cruds'
import {AttendanceSlices} from './slices'
import {handleNotificationWithToastify} from '../../../app/services/Functional'
import {callTypes, catchError, endCall, startCall} from '../common/slices'

const actions = AttendanceSlices.actions
const API_URL = `${process.env.REACT_APP_API_BASE_URL}attendances/`

export const getAttendanceById = (id: number, type: string) => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .getRequest(`${API_URL}${id}/${type}`)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      dispatch(actions.attendance(response.data.data))
    })
    .catch(error => {
      error.clientMessage = 'Can\'t find attendance'
      dispatch(catchError({error, callType: callTypes.action}))
    })
}

export const checkTodayAttendance = () => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .getRequest(`${API_URL}check-today-attendance`)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      dispatch(actions.todayAttendance(response.data.data))
    })
    .catch(error => {
      error.clientMessage = 'Can\'t check attendance'
      dispatch(catchError({error, callType: callTypes.action}))
    })
}

export const checkInAttendance = (payload: {date: Date, check_in: string}) => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .postRequest(`${API_URL}check-in`, payload)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      handleNotificationWithToastify(response.data)
      dispatch(checkTodayAttendance())
      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t check in attendance'
      dispatch(catchError({error, callType: callTypes.action}))
      return error
    })
}

export const checkOutAttendance = (payload: {check_out: string}) => (dispatch: any) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .postRequest(`${API_URL}check-out`, payload)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      handleNotificationWithToastify(response.data)
      dispatch(checkTodayAttendance())
      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t check out attendance'
      dispatch(catchError({error, callType: callTypes.action}))
      return error
    })
}

export const updateAttendance = (payload: any) => (dispatch: (arg0: {payload: any; type: string}) => void) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .patchRequest(`${API_URL}${payload.id}`, payload)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      handleNotificationWithToastify(response.data)
      return response.data
    })
    .catch(error => {
      error.clientMessage = 'Can\'t update attendance'
      dispatch(catchError({error, callType: callTypes.action}))
      return error
    })
}

export const deleteAttendance = (id: number) => (dispatch: (arg0: {payload: any; type: string}) => void) => {
  dispatch(startCall({callType: callTypes.action}))
  return requestFromServer
    .deleteRequest(`${API_URL}${id}`)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      handleNotificationWithToastify(response.data)
    })
    .catch(error => {
      error.clientMessage = 'Can\'t delete attendance'
      dispatch(catchError({error, callType: callTypes.action}))
    })
}

export const getAttendanceCalenderData = (payload: any) => (dispatch: any) => {
  dispatch(startCall({ callType: callTypes.action }));
  return requestFromServer
    .postRequest(`${API_URL}calendar-data`, payload)
    .then(response => {
      dispatch(endCall({callType: callTypes.action}))
      dispatch(actions.attendancesCalenderData(response.data.data));
      return response.data.data;
    })
    .catch(error => {
      error.clientMessage = "Can't find data";
      dispatch(catchError({ error, callType: callTypes.action }));
    });
}