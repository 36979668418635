import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {postRequest} from '../../../../setup/redux/common/cruds'
import {Loader} from '../../../services/Loader'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {AuthFooter} from '../../../services/Functional'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasErrors, setHasErrors] = useState<any>(null)
  const {config}: any = useSelector<RootState>(
    (state) => ({
      config: state.configuration.configurations,
    }),
    shallowEqual,
  )
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values: any, {resetForm, setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        const payload: any = {
          email: values.email,
        }
        postRequest(
          `${process.env.REACT_APP_API_BASE_URL}auth/forgot-password`, payload,
        )
          .then(response => {
            if (response.data.success) {
              setHasErrors(false)
              resetForm()
            } else {
              setHasErrors(true)
            }
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      {loading && <Loader />}
      <div className="row w-100 h-100 p-0 m-0">
        <div className="col-md-6 background_card">
          <img src={toAbsoluteUrl("/media/actual_logo.png")} alt="" />
        </div>
        <div className="col-md-6 p-0 bg-body">
          <div className="login_card">
            <form
              className="login_form"
              id="login_form"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className="mb-15 animate__animated animate__fadeInUp">
                <h2 className="font-weight-bold">Forgot Password</h2>
                <div className="fs-4">
                  Enter your email to reset your password.
                </div>
              </div>

              {hasErrors === true && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    Sorry, looks like there are some errors detected, please try
                    again.
                  </div>
                </div>
              )}

              {hasErrors === false && (
                <div className="mb-10 bg-light-info p-8 rounded">
                  <div className="text-info">
                    Please check your email for reset password link.
                  </div>
                </div>
              )}

              <div className="form-group mb-10 animate__animated animate__fadeInUp">
                <label className="font-size-h6" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Ex: someone@email.com"
                  autoComplete="off"
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control custom_input',
                    {
                      'is-invalid': formik.touched.email && formik.errors.email,
                    },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    },
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="animate__animated animate__fadeInUp">
                <button disabled={loading} type="submit" className="btn btn-primary me-3">
                  <span className="indicator-label">Submit </span>
                </button>

                <Link to="/auth/login">
                  <button
                    type="button"
                    id="kt_login_password_reset_form_cancel_button"
                    className="btn btn-light-primary"
                  >
                    Cancel
                  </button>
                </Link>
              </div>
              <AuthFooter />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}