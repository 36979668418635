import React, {useState} from 'react'
import {Card, Col, Row, Tab, Tabs} from 'react-bootstrap'
import Select from 'react-select'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {Input} from '../../../../../_metronic/partials/controls'
import RenderSmoothImage from '../../../hotels/pages/components/steps/RenderSmoothImage'
import DummyHotel from '../../../../../_metronic/assets/hotel-colour.jpg'
import {DateFormat, getRoomInclusion} from '../../../../services/Functional'

interface props {
  items: any
  handleChange: any
  handleBlur: any
  index: number
}

export const PrefixOption = [
  {label: 'Select', value: ''},
  {label: 'Mr.', value: 'mr'},
  {label: 'Mrs.', value: 'mrs'},
  {label: 'Ms.', value: 'ms'},
  {label: 'Mstr.', value: 'mstr'},
]
const HotelCheckOutDetails = ({items, index, handleChange}: props) => {
  const [showHotelDetails, setShowHotelDetails] = useState<any[]>([0])
  const {values}: any = useFormikContext()
  const showHotel = (index: number) => {
    let tempData = [...showHotelDetails]
    if (tempData.indexOf(index) !== -1) {
      tempData.splice(tempData.indexOf(index), 1)
      setShowHotelDetails(tempData)
    } else {
      tempData.push(index)
      setShowHotelDetails(tempData)
    }
  }

  const [key, setKey] = useState<string>(`room_${index}_1`)

  return (
    <React.Fragment>
      <Card className={'gutter-b'}>
        <Card.Body className={'p-2 '}>
          <div
            role='button'
            className={`${
              showHotelDetails.includes(index) ? 'border-bottom' : ''
            } py-2 px-3 d-flex justify-content-between align-items-center`}
            onClick={() => showHotel(index)}
          >
            <div className={'fs-4 fw-semibold fs-3 d-flex align-items-center'}>
              <i className='las la-procedures me-2 fs-1' />
              <span className='me-1'>{items?.hotel?.name}</span>
              (
              <div className={'fw-semibold text-primary fs-6'}>
                Total Price(USD):
                <span
                  className={'text-muted ms-1'}>{items?.room_info.reduce((roomAcc: any, roomPrice: any) => roomAcc + parseFloat(roomPrice?.roomData
                  ?.price), 0).toFixed(2)}</span>
              </div>
              )
            </div>
            <div>
              {showHotelDetails.includes(index) ? (
                <i className='las la-chevron-circle-up fs-2x' />
              ) : (
                <i className='las la-chevron-circle-down fs-2x' />
              )}
            </div>
          </div>
          {showHotelDetails.includes(index) && (
            <Row>
              <Col md={4}>
                <div className={'spinnerCenter position-relative py-2'}>
                  <div className={'d-flex gap-2 border-bottom mb-2 pb-1'}>
                    <RenderSmoothImage
                      src={items?.hotel?.logo ? (process.env.REACT_APP_AWS_URL + items?.hotel?.logo) : DummyHotel}
                      extraClassName={'img-fluid rounded h-75px'}
                    />
                    <div className={' fs-4 fw-semibold pb-1'}>
                      {items?.hotel.name}
                      <div className={'fw-semibold text-primary fs-6 mb-1 '}>
                        Total Price(USD):
                        <span
                          className={'text-muted ms-1'}>{items?.room_info.reduce((roomAcc: any, roomPrice: any) => roomAcc + parseFloat(roomPrice?.roomData
                          ?.price), 0).toFixed(2)}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={'text-center my-2 fw-semibold bg-gray-200i py-1 fs-5 text-gray-700'}
                  >
                    Room Information
                  </div>
                  <Tabs
                    id='controlled-tab-example'
                    defaultActiveKey={`room_${index}_1`}
                    onSelect={(k: any) => setKey(k)}
                    className='mb-3 '
                  >
                    {items?.room_info && items?.room_info.length !== 0 && items?.room_info.map((room: any, room_index: number) => (
                      <Tab
                        key={`room_${index}_${room_index + 1}`}
                        eventKey={`room_${index}_${room_index + 1}`}
                        title={`Room ${room_index + 1}`}
                        tabClassName={`${key === `room_${index}_${room_index + 1}` ? 'fw-bold bg-gray-300i' : ''}`}
                      >
                        <div className={'fw-semibold text-primary fs-6 mb-3 ps-4'}>
                          Room Type:
                          <span
                            className={'text-muted ms-1'}>{room?.roomData?.name}({getRoomInclusion(room.roomData.room_inclusion)})</span>
                        </div>
                        <div className={'d-flex gap-5 mb-3 text-primary ps-4 fw-semibold fs-6'}>
                          <div>
                            Check in Date
                            <DateFormat items={items?.booking_info?.check_in} />
                          </div>
                          <div>
                            Check out Date
                            <DateFormat items={items?.booking_info?.check_out} />
                          </div>
                        </div>
                        <div className={'fw-semibold fs-6 ps-4'}>
                          <div className={'text-muted mb-3'}>
                            ({items?.booking_info?.total_night} Nights) | 1 ROOM |{' '}
                            {room?.searchData?.adult} ADULTS
                            {room?.searchData?.child_count !== 0 &&
                            <React.Fragment>
                              {' '}|{' '}
                              {room?.searchData?.child_count}{' '}
                              CHILD({room?.searchData?.child.map((child: any, child_index: number) => (
                              <span
                                key={`child_age_${child_index}`}>{child.age} {child?.child_bed_type === true ? 'CWB' : 'CNB'}</span>
                            ))}) Yrs
                            </React.Fragment>
                            }
                          </div>
                          <div className={'fw-semibold text-gray-800 fs-6 pb-1'}>
                            <div className={'text-primary'}>
                              Room Price(USD):{' '}
                              <span className={'text-muted ms-1'}>{room?.roomData?.price}/-</span>
                            </div>
                          </div>
                          <div className={'fw-semibold text-gray-800 fs-6 pb-1'}>
                            <div className={'text-danger d-flex'}>
                              Cancel Up To:{' '} <span className={'text-muted ms-1'}><DateFormat
                              items={room?.roomData?.cancel_upto} /></span>
                            </div>
                            {!room?.roomData?.availability && <span className="text-danger"> (On Request)</span>}
                          </div>
                        </div>
                      </Tab>
                    ))
                    }
                  </Tabs>
                </div>
              </Col>
              <Col md={8} className={'border-start'}>
                <Row className={'mt-2'}>
                  <Col md={12}>
                    <div className='text-danger mt-1 fw-semibold my-2 text-center'>
                      PASSENGER DETAILS
                    </div>
                  </Col>
                </Row>
                <Row>
                  {items?.room_info && items?.room_info.length !== 0 && items?.room_info.map((room: any, room_index: number) => (
                    <React.Fragment key={`Rooms_information_${room_index}`}>
                      <Col md={12}>
                        <div
                          onClick={(e: any) => {
                            setKey(`room_${index}_${room_index + 1}`)
                          }}
                          role='button'
                          className={'d-flex justify-content-between mb-3 bg-gray-300i p-2 rounded'}
                        >
                          <div className={'fw-semibold text-gray-700 fs-4'}>
                            Room {room_index + 1} Passenger Information
                          </div>
                          <div>
                            {key == `room_${index}_${room_index + 1}` ? (
                              <i className='las la-chevron-circle-up fs-2x text-gray-800' />
                            ) : (
                              <i className='las la-chevron-circle-down fs-2x text-gray-800' />
                            )}
                          </div>
                        </div>
                        {Array.from({length: room?.searchData?.adult}, (_: any, passIndex: number) => (
                          <React.Fragment key={`OTHER_PASSENGER_DETAILS${passIndex}`}>
                            <Row
                              className={`px-2 ${key == `room_${index}_${room_index + 1}` ? '' : 'd-none'}`}
                            >
                              <Col md={2}>
                                <div className={'form-group'}>
                                  <label className='form-label'>Prefix</label>
                                  <Select
                                    options={PrefixOption}
                                    value={PrefixOption.find((prefix) => prefix.value === values?.hotels[index]?.rooms[room_index]?.adults[passIndex]?.prefix)}
                                    name={`hotels[${index}].rooms[${room_index}].adults[${passIndex}].prefix`}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    placeholder='Select a prefix'
                                    onChange={(option: any) => {
                                      let event = {
                                        target: {
                                          name: `hotels[${index}].rooms[${room_index}].adults[${passIndex}].prefix`,
                                          value: option.value,
                                        },
                                      }
                                      handleChange(event)
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`hotels[${index}].rooms[${room_index}].adults[${passIndex}].prefix`}
                                    component='div' className='text-danger mt-2' />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className={'form-group'}>
                                  <label className='form-label'>First Name</label>
                                  <Field
                                    name={`hotels[${index}].rooms[${room_index}].adults[${passIndex}].first_name`}
                                    component={Input}
                                    placeholder={'first name'}
                                  />
                                  <ErrorMessage
                                    name={`hotels[${index}].rooms[${room_index}].adults[${passIndex}].first_name`}
                                    component='div' className='text-danger mt-2' />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className={'form-group'}>
                                  <label className='form-label'>Last Name</label>
                                  <Field
                                    name={`hotels[${index}].rooms[${room_index}].adults[${passIndex}].last_name`}
                                    component={Input}
                                    placeholder={'last name'}
                                  />
                                  <ErrorMessage
                                    name={`hotels[${index}].rooms[${room_index}].adults[${passIndex}].last_name`}
                                    component='div' className='text-danger mt-2' />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className={'form-group'}>
                                  <label className='form-label'>PAN Number</label>
                                  <Field
                                    name={`hotels[${index}].rooms[${room_index}].adults[${passIndex}].pan_number`}
                                    component={Input}
                                    placeholder={'Pan number'}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                        {Array.from({length: room?.searchData?.child_count}, (_: any, passIndex: number) => (
                          <React.Fragment key={`CHILD_PASSENGER_DETAILS_${passIndex}`}>
                            <Row
                              className={`px-2 ${key == `room_${index}_${room_index + 1}` ? '' : 'd-none'}`}
                            >
                              <Col md={2}>
                                <div className={'form-group'}>
                                  <label className='form-label'>Prefix</label>
                                  <Select
                                    options={PrefixOption}
                                    value={PrefixOption.find((prefix) => prefix.value === values?.hotels[index]?.rooms[room_index]?.children[passIndex]?.prefix)}
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].prefix`}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    placeholder='Select a prefix'
                                    onChange={(option: any) => {
                                      let event = {
                                        target: {
                                          name: `hotels[${index}].rooms[${room_index}].children[${passIndex}].prefix`,
                                          value: option.value,
                                        },
                                      }
                                      handleChange(event)
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].prefix`}
                                    component='div' className='text-danger mt-2' />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className={'form-group'}>
                                  <label className='form-label'>First Name</label>
                                  <Field
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].first_name`}
                                    component={Input}
                                    placeholder={'first name'}
                                  />
                                  <ErrorMessage
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].first_name`}
                                    component='div' className='text-danger mt-2' />
                                </div>
                              </Col>
                              <Col md={3}>
                                <div className={'form-group'}>
                                  <label className='form-label'>Last Name</label>
                                  <Field
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].last_name`}
                                    component={Input}
                                    placeholder={'last name'}
                                  />
                                  <ErrorMessage
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].last_name`}
                                    component='div' className='text-danger mt-2' />
                                </div>
                              </Col>
                              <Col md={4}>
                                <div className={'form-group'}>
                                  <label className='form-label'>Age</label>
                                  <Field
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].age`}
                                    component={Input}
                                    placeholder={'age'}
                                  />
                                  <ErrorMessage
                                    name={`hotels[${index}].rooms[${room_index}].children[${passIndex}].age`}
                                    component='div' className='text-danger mt-2' />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        ))}
                      </Col>
                    </React.Fragment>
                  ))}
                </Row>
                <Row>
                  <Col md={12} className="mt-4 px-5">
                    <div className={'form-group'}>
                      <label className='form-label text-danger'>Special Request</label>
                      <Field name={`hotels[${index}].special_request`} as='textarea' className='form-control' rows={2} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export {HotelCheckOutDetails}
