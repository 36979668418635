import {createSlice} from '@reduxjs/toolkit'

const initialMasterHotel = {
  totalCount: 0,
  masterHotels: null,
  masterHotel: undefined,
  masterHotelShow: undefined,
  hotelDropdown: [],
  hotelDropdownCityFrom: [],
  hotelDropdownCityTo: [],
}

export const MasterHotelSlices = createSlice({
  name: 'master_hotels',
  initialState: initialMasterHotel,
  reducers: {
    masterHotel: (state, action) => {
      state.masterHotel = action.payload.masterHotel
    },
    masterHotelShow: (state, action) => {
      state.masterHotelShow = action.payload.masterHotel
    },
    masterHotels: (state, action) => {
      state.masterHotels = action.payload.masterHotels
      state.totalCount = action.payload.total_count
    },
    hotelDropdown: (state, action) => {
      if (action.payload.type === 'city_from') {
        state.hotelDropdownCityFrom = action.payload.data.hotels
      } else if (action.payload.type === 'city_to') {
        state.hotelDropdownCityTo = action.payload.data.hotels
      } else {
        state.hotelDropdown = action.payload.data.hotels
      }
    },
  },
})