import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import * as auth_actions from '../../../../setup/redux/auth/actions'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {RootState} from '../../../../setup'
import {Loader} from '../../../services/Loader'
import {AuthFooter} from '../../../services/Functional'
import Cookies from 'js-cookie'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const loginSchema = Yup.object().shape({
  username: Yup.string().email('Invalid email format.')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required'),
})

export function Login() {
  const initialValues = {
    username: Cookies.get('username'),
    password: Cookies.get('password'),
  }
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [passwordType, setPasswordType] = useState<string>('password')
  const {actionsLoading, config, user, access_token}: any = useSelector<RootState>(
    (state) => ({
      actionsLoading: state.common.actionsLoading,
      config: state.configuration.configurations,
      user: state.auth.user,
      access_token: state.auth.access_token,
    }),
    shallowEqual,
  )
  React.useEffect(() => {
    if (Cookies.get('username') && Cookies.get('password')) {
      setRememberMe(true)
    }
  }, [])
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      if (rememberMe) {
        if (values.username && values.password) {
          Cookies.set('username', values.username, {expires: 365})
          Cookies.set('password', values.password, {expires: 365})
        }
      } else {
        Cookies.remove('username')
        Cookies.remove('password')
      }
      let response: any = await dispatch<any>(auth_actions.login(values))
      if (response.status === 200 || response.status === 201) {
        setHasErrors(false)
        if (response.data.data.user.is_two_factor_auth === true) {
          navigate('/auth/otp')
        } else {
          await dispatch<any>(auth_actions.getUserAccess())
          navigate('/dashboard')
        }
      } else {
        setHasErrors(true)
        setMessage(response.message)
        setTimeout(() => {
          setHasErrors(false)
        }, 5000)
      }
    },
  })

  useEffect(() => {
    if (access_token) {
      navigate('/dashboard')
    }
  }, [user])


  return (
    <>
      {actionsLoading && <Loader />}
      <div className="row w-100 h-100 p-0 m-0">
        <div className="col-md-6 background_card">
          {/*<img src={config && config?.actual_logo} alt="" />*/}
          <img src={toAbsoluteUrl("/media/actual_logo.png")} alt="" />
        </div>

        <div className="col-md-6 p-0 bg-body">
          <div className="login_card">
            <form
              className="login_form"
              id="login_form"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <div className="mb-15 animate__animated animate__fadeInUp">
                <h2 className="font-weight-bolder">Login</h2>
                <div className="fs-4">
                  Use your given credentials to login.
                </div>
              </div>
              {hasErrors && (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {message}
                  </div>
                </div>
              )}

              <div className="form-group animate__animated animate__fadeInUp">
                <label className="font-size-h6" htmlFor="username">
                  Email
                </label>
                <input type="text" tabIndex={1}
                       placeholder="mail@website.com" autoComplete="off"
                       {...formik.getFieldProps('username')} className="form-control custom_input" />
                {formik.touched.username && formik.errors.username && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.username}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className="form-group mb-10 animate__animated animate__fadeInUp">
                <label className="font-size-h6" htmlFor="password">
                  Password
                </label>
                <input type={passwordType} tabIndex={2} autoComplete="off" placeholder="********"
                       {...formik.getFieldProps('password')} className="form-control custom_input" />
                <i className={`fa ${passwordType === 'password' ? 'fa-eye-slash' : 'fa fa-eye'}`}
                   onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                   style={{margin: '-27px 5px 0 0', float: 'right', cursor: 'pointer'}} />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="fv-row mb-10 d-flex justify-content-between animate__animated animate__fadeInUp">
                <div className="form-group d-flex vertical-align-middle">
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={rememberMe}
                      onChange={(e) => {
                        setRememberMe(e.target.checked)
                      }}
                    />
                  </div>
                  <label htmlFor="rememberMe mb-0" style={{marginBottom: 0, marginLeft: '6px'}}>Remember me</label>
                </div>
                <Link
                  to="/auth/forgot-password"
                  className="link-primary fs-6 float-end">
                  Forgot Password ?
                </Link>
              </div>
              <button
                type="submit" disabled={actionsLoading} tabIndex={3}
                className="btn btn-primary btn-pill animate__animated animate__fadeInUp w-100">
                Login
              </button>
              <AuthFooter />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
