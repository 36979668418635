/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {App} from '../App'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {getConfiguration} from '../../setup/redux/configurations/actions'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import {registerPlugin} from 'filepond'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize);

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const dispatch = useDispatch()
  const isAuthorized = useSelector<RootState>(({auth}) => auth.access_token, shallowEqual)
  const {config}: any = useSelector<RootState>(
    (state) => ({
      config: state.configuration.configurations
    }),
    shallowEqual,
  )

  useEffect(() => {
    if (!config) {
      dispatch<any>(getConfiguration())
    }
    const faviconEl = document.getElementById('faviconEl')
    if (faviconEl) {
      if (config && config?.favicon) {
        // @ts-ignore
        faviconEl.href = process.env.REACT_APP_AWS_URL + config?.favicon
      }
    }
    const splashScreenEl = document.getElementById('splashScreenEl')
    if (splashScreenEl) {
      if (config && config.old_logo) {
        // @ts-ignore
        splashScreenEl.src = process.env.REACT_APP_AWS_URL + config?.logo
      }
    }
  }, [config])
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthorized ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
