import React, {useState} from 'react'
import {Card, Col, Row, Tab, Tabs} from 'react-bootstrap'
import Select from 'react-select'
import {ErrorMessage, Field, useFormikContext} from 'formik'
import {Input} from '../../../../../_metronic/partials/controls'
import RenderSmoothImage from '../../../hotels/pages/components/steps/RenderSmoothImage'
import DummySightSeeing from '../../../../../_metronic/assets/hotel-colour.jpg'
import moment from 'moment'
import {DateFormat, TimeFormat} from "../../../../services/Functional";
import {PrefixOption} from "../hotel_checkout_details/HotelCheckOutDetails";

interface props {
  items: any
  handleChange: any
  handleBlur: any
  index: number
}

const SightSeeingCheckOutDetails = ({items, index, handleChange, handleBlur}: props) => {
  const [showSightSeeingDetails, setShowSightSeeingDetails] = useState<any[]>([0])

  const {values}: any = useFormikContext()
  const showSightSeeing = (index: number) => {
    let tempData = [...showSightSeeingDetails]
    if (tempData.indexOf(index) !== -1) {
      tempData.splice(tempData.indexOf(index), 1)
      setShowSightSeeingDetails(tempData)
    } else {
      tempData.push(index)
      setShowSightSeeingDetails(tempData)
    }
  }

  const [key, setKey] = useState<string>(`room_${index}_1`)

  return (
    <React.Fragment>
      <Card className={'gutter-b'}>
        <Card.Body className={'p-2 '}>
          <div
            role='button'
            className={`${
              showSightSeeingDetails.includes(index) ? 'border-bottom' : ''
            } py-2 px-3 d-flex justify-content-between align-items-center`}
            onClick={() => showSightSeeing(index)}
          >
            <div className={'fs-4 fw-semibold fs-3 d-flex align-items-center'}>
              <i className='fa-solid fa-camera me-2 fs-2' />
              <span className='me-1'>{items?.sight_seeing?.name}</span>
              (
              <div className={'fw-semibold text-primary fs-6'}>
                Total Price(USD):
                <span
                  className={'text-muted ms-1'}>{items?.price}</span>
              </div>
              )
            </div>
            <div>
              {showSightSeeingDetails.includes(index) ? (
                <i className='las la-chevron-circle-up fs-2x' />
              ) : (
                <i className='las la-chevron-circle-down fs-2x' />
              )}
            </div>
          </div>
          {showSightSeeingDetails.includes(index) && (
            <Row>
              <Col md={4}>
                <div className={'spinnerCenter position-relative py-2'}>
                  <div className={'d-flex gap-2 border-bottom mb-2 pb-1'}>
                    <RenderSmoothImage
                      src={items?.sight_seeing?.logo ? (process.env.REACT_APP_AWS_URL + items?.sight_seeing?.logo) : DummySightSeeing}
                      extraClassName={'img-fluid rounded h-75px'}
                    />
                    <div className={' fs-4 fw-semibold pb-1'}>
                      {items?.sight_seeing.name}
                      <div className={'fw-semibold text-primary fs-6 mb-1 '}>
                        Total Price(USD):
                        <span
                          className={'text-muted ms-1'}>{items?.price}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={'text-center my-2 fw-semibold bg-gray-200i py-1 fs-5 text-gray-700'}
                  >
                    Sight Seeing Information
                  </div>

                  <div className={'fw-semibold text-primary fs-6 mb-3 ps-4'}>
                    Category:
                    <span className={'text-muted ms-1'}>{items?.category}</span>
                  </div>
                  <div className={'d-flex gap-1 mb-2 text-primary ps-4 fw-semibold fs-6'}>
                      <span>Pickup Date:</span><DateFormat items={items?.searchData?.pickup_date} />
                  </div>
                  <div className={'d-flex gap-1 mb-2 text-primary ps-4 fw-semibold fs-6'}>
                    <span>Pickup Time:</span><TimeFormat time={items?.pickup_time} />
                  </div>
                  <div className={'d-flex gap-1 mb-2 text-primary ps-4 fw-semibold fs-6'}>
                    <span>Tour Start Time:</span><TimeFormat time={items?.start_time} />
                  </div>
                  <div className={'d-flex gap-1 mb-2 text-primary ps-4 fw-semibold fs-6'}>
                    <span>Tour End Time:</span><TimeFormat time={items?.end_time} />
                  </div>
                  <div className={'d-flex gap-1 mb-2 text-primary ps-4 fw-semibold fs-6'}>
                    <span>Pickup Type:</span><span className="text-muted">{items.type}</span>
                  </div>
                  <div className={'d-flex gap-1 mb-2 text-primary ps-4 fw-semibold fs-6'}>
                    <span>Pickup Hotel:</span>
                    <span className="text-muted">
                      {items?.hotel_data?.name}<br/>
                      {items?.hotel_data?.address}
                    </span>
                  </div>
                  <div className={'fw-semibold fs-6 ps-4'}>
                    <div className={'text-muted mb-3'}>
                      {items?.searchData?.adult} ADULTS
                      {items?.searchData?.child_count !== 0 &&
                          <React.Fragment>
                            {' '}|{' '}
                            {items?.searchData?.child_count}{' '}
                            CHILD({items?.searchData?.child.map((child: any, child_index: number) => (
                              <span
                                  key={`child_age_${child_index}`}>{child.age}, </span>
                          ))}) Yrs
                          </React.Fragment>
                      }
                    </div>
                    <div className={'fw-semibold text-gray-800 fs-6 pb-1'}>
                      <div className={'text-danger d-flex'}>
                        Cancel Up To:{' '} <span className={'text-muted ms-1'}><DateFormat items={items?.sight_seeing?.cancel_upto}/></span>
                      </div>
                      {!items.sight_seeing.availability && <span className="text-danger"> (On Request)</span>}
                    </div>
                  </div>

                </div>
              </Col>
              <Col md={8} className={'border-start'}>
                <Row className={'mt-2'}>
                  <Col md={3}>
                    <div className={'form-group'}>
                      <label className='form-label'>{items.searchData.transfer_type} Pickup Time</label>
                      <Field
                        name={`sight_seeings[${index}].pickup_time`}
                        type="time"
                        component={Input}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={'mt-2'}>
                  <Col md={12}>
                    <div className='text-danger mt-1 fw-semibold my-2 text-center'>
                      PASSENGER DETAILS
                    </div>
                  </Col>
                </Row>
               <Row>
                  <Col md={12}>
                    {values?.sight_seeings && values?.sight_seeings[index]?.adults.length !== 0 && Array.from({length: values?.sight_seeings[index]?.adults.length}, (_: any, passIndex: number) => (
                        <React.Fragment key={`OTHER_PASSENGER_DETAILS${passIndex}`}>
                          <Row className="px-2">
                            <Col md={2}>
                              <div className={'form-group'}>
                                <label className='form-label'>Prefix</label>
                                <Select
                                    options={PrefixOption}
                                    value={PrefixOption.find((prefix) => prefix.value === values?.sight_seeings[index]?.adults[passIndex]?.prefix)}
                                    name={`sight_seeings[${index}].adults[${passIndex}].prefix`}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                    placeholder='Select a prefix'
                                    onChange={(option: any) => {
                                      let event = {
                                        target: {
                                          name: `sight_seeings[${index}].adults[${passIndex}].prefix`,
                                          value: option.value,
                                        },
                                      }
                                      handleChange(event)
                                    }}
                                />
                                <ErrorMessage
                                    name={`sight_seeings[${index}].adults[${passIndex}].prefix`}
                                    component='div' className='text-danger mt-2' />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className={'form-group'}>
                                <label className='form-label'>First Name</label>
                                <Field
                                    name={`sight_seeings[${index}].adults[${passIndex}].first_name`}
                                    component={Input}
                                    placeholder={'first name'}
                                />
                                <ErrorMessage
                                    name={`sight_seeings[${index}].adults[${passIndex}].first_name`}
                                    component='div' className='text-danger mt-2' />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className={'form-group'}>
                                <label className='form-label'>Last Name</label>
                                <Field
                                    name={`sight_seeings[${index}].adults[${passIndex}].last_name`}
                                    component={Input}
                                    placeholder={'last name'}
                                />
                                <ErrorMessage
                                    name={`sight_seeings[${index}].adults[${passIndex}].last_name`}
                                    component='div' className='text-danger mt-2' />
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className={'form-group'}>
                                <label className='form-label'>PAN Number</label>
                                <Field
                                    name={`sight_seeings[${index}].adults[${passIndex}].pan_number`}
                                    component={Input}
                                    placeholder={'Pan number'}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                    ))}
                  </Col>
                 <Col md={12}>
                   {values?.sight_seeings && values?.sight_seeings[index]?.children.length !== 0 && Array.from({length: values?.sight_seeings[index]?.children.length}, (_: any, passIndex: number) => (
                     <React.Fragment key={`CHILD_PASSENGER_DETAILS_${passIndex}`}>
                       <Row
                           className='px-2'
                       >
                         <Col md={2}>
                           <div className={'form-group'}>
                             <label className='form-label'>Prefix</label>
                             <Select
                                 options={PrefixOption}
                                 value={PrefixOption.find((prefix) => prefix.value === values?.sight_seeings[index]?.children[passIndex]?.prefix)}
                                 name={`sight_seeings[${index}].children[${passIndex}].prefix`}
                                 className='react-select-container'
                                 classNamePrefix='react-select'
                                 placeholder='Select a prefix'
                                 onChange={(option: any) => {
                                   let event = {
                                     target: {
                                       name: `sight_seeings[${index}].children[${passIndex}].prefix`,
                                       value: option.value,
                                     },
                                   }
                                   handleChange(event)
                                 }}
                             />
                             <ErrorMessage
                                 name={`sight_seeings[${index}].children[${passIndex}].prefix`}
                                 component='div' className='text-danger mt-2' />
                           </div>
                         </Col>
                         <Col md={3}>
                           <div className={'form-group'}>
                             <label className='form-label'>First Name</label>
                             <Field
                                 name={`sight_seeings[${index}].children[${passIndex}].first_name`}
                                 component={Input}
                                 placeholder={'first name'}
                             />
                             <ErrorMessage
                                 name={`sight_seeings[${index}].children[${passIndex}].first_name`}
                                 component='div' className='text-danger mt-2' />
                           </div>
                         </Col>
                         <Col md={3}>
                           <div className={'form-group'}>
                             <label className='form-label'>Last Name</label>
                             <Field
                                 name={`sight_seeings[${index}].children[${passIndex}].last_name`}
                                 component={Input}
                                 placeholder={'last name'}
                             />
                             <ErrorMessage
                                 name={`sight_seeings[${index}].children[${passIndex}].last_name`}
                                 component='div' className='text-danger mt-2' />
                           </div>
                         </Col>
                         <Col md={4}>
                           <div className={'form-group'}>
                             <label className='form-label'>Age</label>
                             <Field
                                 name={`sight_seeings[${index}].children[${passIndex}].age`}
                                 component={Input}
                                 placeholder={'age'}
                             />
                             <ErrorMessage
                                 name={`sight_seeings[${index}].children[${passIndex}].age`}
                                 component='div' className='text-danger mt-2' />
                           </div>
                         </Col>
                       </Row>
                     </React.Fragment>
                 ))}
                 </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-4 px-5">
                    <div className={'form-group'}>
                      <label className='form-label text-danger'>Special Request</label>
                      <Field name={`sight_seeings[${index}].special_request`} as='textarea' className='form-control' rows={2} />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export {SightSeeingCheckOutDetails}
