import {createSlice} from '@reduxjs/toolkit'

const initialDocumentTypeState = {
  totalCount: 0,
  document_types: null,
  document_type: undefined,
  documentsDropdown: null
}

export const DocumentTypeSlices = createSlice({
  name: 'document_types',
  initialState: initialDocumentTypeState,
  reducers: {
    document_type: (state, action) => {
      state.document_type = action.payload.document_type
    },

    document_types: (state, action) => {
      state.document_types = action.payload.document_types
      state.totalCount = action.payload.total_count
    },
    documentList: (state, action) => {
      state.documentsDropdown = action.payload.documents;
    }
  },
})