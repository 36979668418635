import {createSlice} from '@reduxjs/toolkit'

const initialBookingState = {
    totalCount: 0,
    bookings: null,
    booking: undefined,
    hotels: null,
    sightSeeings: null,
    transfers: null,
    packages: null,
    customServices: null,
    customHotelServices: null,
    selectedAgentID: null,
    agentWalletInfo: null,
    guestNationality: 97,
    guestDestinationCountry: 0,
    searchHotel: [],
    searchSightSeeing: [],
    searchTransfer: [],
    searchPackage: [],
    activeServiceIndex: '1',
    cartItem: {
        hotels: [],
        sightSeeings: [],
        transfers: [],
        packages: [],
    },
    itinerary: {
        isPackage: true,
        bookingDetails: {
            pnr_no: '',
            agent_ref_no : '',
            lead_guest: '',
            start_date: '',
            end_date: '',
            adults: 0,
            child:0,
            infant:0,
            total_pax:0,
            nationality: '',
        },
        uniqueAdultTravelers: [],
        uniqueChildTravelers:[],
        flightDetails: [],
        hotels: [],
        services: []
    },
    updatePackageHotel: {
        initData: [],
        hotelDropdown: []
    },
    creditNote: null,
    invoiceNote: null
}

export const BookingSlices = createSlice({
    name: 'bookings',
    initialState: initialBookingState,
    reducers: {
        booking: (state, action) => {
            state.booking = action.payload.booking
        },
        hotels: (state, action) => {
            state.hotels = action.payload.hotels
        },
        sightSeeings: (state, action) => {
            state.sightSeeings = action.payload.sight_seeings
        },
        transfers: (state, action) => {
            state.transfers = action.payload.transfers
        },
        packages: (state, action) => {
            state.packages = action.payload.packages
        },
        customServices: (state, action) => {
            state.customServices = action.payload.customServices
        },
        customHotelServices: (state, action) => {
            state.customHotelServices = action.payload.customHotelServices
        },
        bookings: (state, action) => {
            state.bookings = action.payload.bookings
            state.totalCount = action.payload.total_count
        },
        selectedAgent: (state, action) => {
            state.selectedAgentID = action.payload
        },
        agentWalletInfo: (state, action) => {
            state.agentWalletInfo = action.payload.agentWallet
        },
        selectedGuestNationality: (state, action) => {
            state.guestNationality = action.payload
        },
        selectedGuestDestinationCountry: (state, action) => {
            state.guestDestinationCountry = action.payload
        },
        searchHotelBooking: (state, action) => {
            state.searchHotel = action.payload
        },
        searchSightSeeingBooking: (state, action) => {
            state.searchSightSeeing = action.payload
        },
        searchTransferBooking: (state, action) => {
            state.searchTransfer = action.payload
        },
        searchPackageBooking: (state, action) => {
            state.searchPackage = action.payload
        },
        addToCartHotels: (state, action) => {
            state.cartItem.hotels = action.payload
        },
        addToCartSightSeeing: (state, action) => {
            state.cartItem.sightSeeings = action.payload
        },
        addToCartPackages: (state, action) => {
            state.cartItem.packages = action.payload
        },
        addToCartTransfers: (state, action) => {
            state.cartItem.transfers = action.payload
        },
        setActiveServiceIndex: (state, action) => {
            state.activeServiceIndex = action.payload
        },
        itineraryDetails: (state, action) => {
            state.itinerary.isPackage = action.payload.isPackage
            state.itinerary.bookingDetails = action.payload.bookingDetails
            state.itinerary.uniqueAdultTravelers = action.payload.uniqueAdultTravelers
            state.itinerary.uniqueChildTravelers = action.payload.uniqueChildTravelers
            state.itinerary.flightDetails = action.payload.flightDetails
            state.itinerary.hotels = action.payload.hotels
            state.itinerary.services = action.payload.services
        },
        creditNote:(state, actions) => {
            state.creditNote = actions.payload.creditNote
        },
        invoiceNote:(state, actions) => {
            state.invoiceNote = actions.payload.invoiceNote
        },
        packageHotelDetails:(state, actions) => {
            state.updatePackageHotel.initData = actions.payload.initData
            state.updatePackageHotel.hotelDropdown = actions.payload.hotelDropdown
        },
        resetBookingInfo: (state, action) => {
            state.totalCount = 0;
            state.booking = undefined;
            state.selectedAgentID = null;
            state.agentWalletInfo = null;
            state.guestNationality = 97;
            state.guestDestinationCountry = 0;
            state.searchHotel = [];
            state.searchSightSeeing = [];
            state.searchTransfer = [];
            state.searchPackage = [];
            state.activeServiceIndex = '1';
            state.cartItem = {
                hotels: [],
                sightSeeings: [],
                transfers: [],
                packages: [],
            }
        },
    },
})

export const {
    selectedAgent,
    agentWalletInfo,
    selectedGuestNationality,
    selectedGuestDestinationCountry,
    searchHotelBooking,
    searchSightSeeingBooking,
    searchTransferBooking,
    searchPackageBooking,
    addToCartHotels,
    addToCartTransfers,
    addToCartPackages,
    addToCartSightSeeing,
    resetBookingInfo,
    setActiveServiceIndex
} = BookingSlices.actions
