import {createSlice} from '@reduxjs/toolkit'

const initialAgentState = {
  totalCount: 0,
  agents: null,
  companyAgents: [],
  agent: undefined,
  agentDropdown: [],
}

export const AgentSlices = createSlice({
  name: 'agents',
  initialState: initialAgentState,
  reducers: {
    agent: (state, action) => {
      state.agent = action.payload.agent
    },
    agentDropdown: (state, action) => {
      state.agentDropdown = action.payload.agents
    },

    agents: (state, action) => {
      state.agents = action.payload.agents
      state.totalCount = action.payload.total_count
    },
    companyAgentsList: (state, action) => {
      state.companyAgents = action.payload.agents
    },
    updateWalletBalance: (state, action) => {
      const agent = state.agent;
      //@ts-ignore
      agent.wallet.balance = agent.wallet.balance + action.payload
      state.agent = agent;
    },
  },
})