import {createSlice} from '@reduxjs/toolkit'

const initialPackageState = {
    totalCount: 0,
    packages: null,
    packageData: undefined,
    packageShow: undefined,
    packagePictures: undefined,
    bookingSearchPackage: [],
    bookingSearchPackageCount: 0
}

export const PackageSlices = createSlice({
    name: 'packages',
    initialState: initialPackageState,
    reducers: {
        package: (state, action) => {
            state.packageData = action.payload.package
        },

        packageShow: (state, action) => {
            state.packageShow = action.payload.package
        },

        packages: (state, action) => {
            state.packages = action.payload.packages
            state.totalCount = action.payload.total_count
        },

        packagePictures: (state, action) => {
            state.packagePictures = action.payload
        },
        packagesByBookingSearch: (state, action) => {
            state.bookingSearchPackage = action.payload.data.packages
            state.bookingSearchPackageCount = action.payload.data.total_count
        },
    },
})