import {createSlice} from '@reduxjs/toolkit'

const initialBannerState = {
  totalCount: 0,
  banners: null,
  banner: undefined,
}

export const BannerSlices = createSlice({
  name: 'banners',
  initialState: initialBannerState,
  reducers: {
    banner: (state, action) => {
      state.banner = action.payload.banner
    },

    banners: (state, action) => {
      state.banners = action.payload.banners
      state.totalCount = action.payload.total_count
    },
  },
})