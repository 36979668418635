import {createSlice} from '@reduxjs/toolkit'

const initialLeaveState = {
  totalCount: 0,
  leaves: null,
  leave: undefined,
}

export const LeaveSlices = createSlice({
  name: 'leaves',
  initialState: initialLeaveState,
  reducers: {
    leave: (state, action) => {
      state.leave = action.payload.leave
    },

    leaves: (state, action) => {
      state.leaves = action.payload.leaves
      state.totalCount = action.payload.total_count
    },
  },
})